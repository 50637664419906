import React, {ChangeEventHandler, MouseEventHandler, useContext, useState} from 'react';
import {useMutation} from "@apollo/client";
import {api, JWT_KEY} from "../../config";
import Overlay from "../../components/Overlay";
import {FaPlusCircle, FaStar, FaTimesCircle} from "react-icons/fa";
import {Button, Buttons} from "../../components/form";
import styled from "styled-components";
import {
    AgentFinderContext,
    getPriceInfo,
    SearchLink
} from "./AgentFinder";
import {TransactionInfo} from "./TransactionInfo";
import {ProviderProps, UpdateInquiryDealProps} from "./Provider";
import {Photo, PhotoDiv} from "./Photo";
import {AgentBlurb} from "./AgentBlurb";
import {AgentPresentedSelected} from "./AgentPresentedSelected";
import {UpdateProviderDocument} from "../../generated/graphql/graphql";
import {loginAsHandler} from "../../lib/login-as";

const StyledInput = styled.input`
    border: 1px solid #666;
`;

const StyledTextArea = styled.textarea`
    border: 1px solid #666;
`;

const ProviderDealPopup = styled.div`
    padding: 2rem;
    min-width: 700px;
    background: #fff;
    
    ${Buttons} {
        margin: 2rem 0 0;
    }
    
    textarea {
        min-height: 70px;
    }
`;

const ProviderDealHeading = styled.div`
    display: flex;
    column-gap: 1rem;
    
    ${PhotoDiv} {
        width: 150px;
        height: 150px;
    }
    
    h2 {
        margin: 0;
    }
`;

const ProviderDealLinks = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ProviderDealData = styled.div`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    
    label {
        text-align: right;
        max-width: 150px;
    }
    
    ${Buttons} {
        margin: 1rem 0 3rem;
        grid-column: span 2;
    }
`;

const ProviderDealLink = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: .5rem;
    
    svg {
        cursor: pointer;
    }
`;

interface ProviderDealProps extends ProviderProps {
    info: any;
    updateInquiryDeal: (update:UpdateInquiryDealProps) => void;
    close: () => void;
}

export const ProviderDeal: React.FC<ProviderDealProps> = (props) => {
    const {
        info,
        updateInquiryDeal,
        potentialProvider,
        agentsPresented,
        present,
        togglePresent,
        showStatus
    } = props;

    const priceInfo = getPriceInfo(potentialProvider.provider?.transactions.nodes);

    const provider = potentialProvider.provider;
    const {city, state} = (provider?.info?.location) || {city:'', state:''};
    const [updateProvider] = useMutation(UpdateProviderDocument);

    let distance:any = potentialProvider.distance || 0;

    if (distance < 0.001) {
        distance = '';
    }
    else {
        if (typeof distance === 'number') {
            if (distance < 1)
                distance = distance.toFixed(2);
            else if (distance < 10)
                distance = distance.toFixed(1);
            else
                distance = distance.toFixed(0);
        }

        distance = <span style={{fontSize: '75%'}}>({distance} mile{distance !== '1' ? 's' : ''})</span>;
    }

    const agentPresented = !!(agentsPresented?.match(provider?.name || 'N/A') || agentsPresented?.match(provider?.email || 'N/A'));
    const engagebayId = potentialProvider?.provider?.engagebay?.id;


    const link = (provider?.info.links?.length && provider.info.links[0]) || '';

    const rating = info.rating || 0;
    const notes = info.notes || '';
    const smsSent = info.sms || '';

    const updateRating = (newRating:number) => () => {
        if (newRating === rating)
            newRating = 0;

        updateInquiryDeal({rating: newRating});
    };

    const saveNotes = (e:any) => {
        const notes = e.target.value;

        updateInquiryDeal({notes});
    }

    const [newLink, setNewLink] = useState('');

    const newLinkChange = (e:any) => {
        setNewLink(e.target.value);
    }

    const newLinkValid = !!newLink.match(/^https:\/\/.+\//);

    const addNewLink = () => {
        if (!(provider && newLinkValid))
            return;

        const links = (provider.info.links || []).slice();

        links.push(newLink);

        updateProvider({
            variables: {
                input: {
                    nodeId: provider.nodeId,
                    patch: {
                        info: Object.assign({}, provider.info, {links})
                    }
                }
            }
        }).then(() => {
            setNewLink('');
        });
    }

    const removeLink = (linkToRemove: string) => {
        if (!provider)
            return;

        if (!window.confirm('Are you sure you want to remove this link?'))
            return;

        const links = (provider.info.links || []).filter((link: string) => link !== linkToRemove);

        updateProvider({
            variables: {
                input: {
                    nodeId: provider.nodeId,
                    patch: {
                        info: Object.assign({}, provider.info, {links})
                    }
                }
            }
        }).then();
    }

    const { deal } = useContext(AgentFinderContext);

    let service;

    switch (deal?.side) {
        case 'Buy':
            service = 'buying';
            break;
        case 'Sell':
            service = 'selling';
            break;
        default:
            service = 'buying and selling';
            break;
    }

    const [sms, setSms] = useState(
        `Hi ${provider?.name.replace(/\s.*/, '')}, this is Malia with RBN. We have an upcoming consult for a consumer ${service.toLowerCase()} a home who may match your profile. Are you accepting referrals?`
    );

    const smsChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
        setSms(e.target.value);
    }

    const sendSms: MouseEventHandler<HTMLOrSVGElement> = () => {
        if (!provider)
            return;

        if (!window.confirm(`Are you sure you want to send a text message to ${provider.name}?`))
            return;

        const body = JSON.stringify({
            to: provider.phone,
            from: '6028992787',
            message: sms
        });

        fetch(`${api.endpoint}/send-sms`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
                'Content-Type': 'application/json'
            },
            body
        })
            .then(() => {
                updateInquiryDeal({sms});
            });
    };

    const completePercent = provider?.profileComplete || 0;

    const incompleteAgent = !!(completePercent < 80 || (completePercent === 80 && provider?.transactions.nodes.length));

    const onTogglePresent = () => {
        if (togglePresent)
            togglePresent();
    }

    return (
        <Overlay onClick={props.close}>
            <ProviderDealPopup>
                <ProviderDealHeading>
                    <Photo url={provider?.image?.url || ''}/>
                    <div>
                        <h2><a href={`/providers/${provider?.id}`} onClick={loginAsHandler(provider?.account)}>{provider?.name}</a></h2>
                        <ProviderDealLinks>
                            {engagebayId && <a href={`https://joinrbn.engagebay.com/home#list/0/subscriber/${engagebayId}`} target="engagebay">EB Link</a>}
                            <SearchLink term={`${provider?.name} real estate ${city} ${state}`}>Google</SearchLink>
                        </ProviderDealLinks>
                        <div>{city && `${city}, ${state}`} {distance}</div>
                        {(priceInfo.count || !showStatus) && <TransactionInfo provider={potentialProvider?.provider}/>}
                        {provider && <div><AgentPresentedSelected agent={provider}/> &nbsp;{provider.syntheticStatus}</div>}
                    </div>
                </ProviderDealHeading>

                <ProviderDealData>
                    <label>Deal Rating:</label>
                    <div>
                        {[1,2,3].map((value) => (
                            <FaStar key={value} color={value <= rating ? '#090' : '#ccc'} onClick={updateRating(value)}/>
                        ))}
                        <span>&nbsp;&nbsp;</span>
                        <FaTimesCircle color={rating === -1 ? '#f00' : '#ccc'} onClick={updateRating(-1)}/>
                    </div>
                    <label>Present Agent:</label>
                    <div>
                        <input type="checkbox" checked={present || agentPresented} onChange={onTogglePresent} disabled={!!(agentsPresented?.trim())}/>
                    </div>
                    {(present && incompleteAgent) && (
                        <AgentWarning>
                            Warning: You are about to present an agent with an incomplete <a href={`/providers/${provider?.id}`} onClick={loginAsHandler(provider?.account)}>profile</a>.
                        </AgentWarning>
                    )}
                    {(present || agentPresented) && provider && (
                        <AgentBlurb potentialProvider={props.potentialProvider} inquiry={props.inquiry}/>
                    )}
                    <label>Internal AF Notes:</label>
                    <StyledTextArea onBlur={saveNotes} defaultValue={notes} id={`notes-${provider?.id}`}/>
                    <label>Links:</label>

                    <ProviderDealLink key={link}>
                        {(provider?.info.links || []).map((link:any) => (
                            <React.Fragment key={link}>
                                <a href={link} target="_blank" rel="noreferrer">{link}</a>
                                <FaTimesCircle color="#f00" onClick={() => removeLink(link)}/>
                            </React.Fragment>
                        ))}

                        <StyledInput value={newLink} onChange={newLinkChange} placeholder="add link" id={`new-link-${provider?.id}`}/>
                        <FaPlusCircle color={newLinkValid ? '#090' : '#ccc'} style={{cursor: newLinkValid ? 'pointer' : 'default'}} onClick={addNewLink}/>
                    </ProviderDealLink>
                    <Buttons>
                        <Button onClick={props.close}>Done</Button>
                    </Buttons>

                    <label>Text Agent:</label>
                    {smsSent || true ? <div style={{maxWidth: "600px"}}>{smsSent || sms}</div> : <textarea value={sms} onChange={smsChange}/>}
                </ProviderDealData>

                {false && <SendSmsButtons>
                    <SendSmsButton onClick={sendSms} disabled={smsSent}>Send Text</SendSmsButton>
                </SendSmsButtons>}
            </ProviderDealPopup>
        </Overlay>
    )
}

const AgentWarning = styled.div`
    grid-column: span 2;
    background: #a00;
    color: #fff;
    padding: .25em;
    text-align: center;
    
    a {
        color: inherit;
    }
`;

const SendSmsButtons = styled(Buttons)`
    justify-content: left;
`;

const SendSmsButton = styled(Button)`
    background-color: #900;
`;