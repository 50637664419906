import AuthenticatedPage from "../../components/AuthenticatedPage";
import {useQuery} from "@apollo/client";
import React from "react";
import PointsLedger from "./PointsLedger";
import {PointsLedgersDocument} from "../../generated/graphql/graphql";

export default function RewardsLog() {
    const { data } = useQuery(PointsLedgersDocument);

    return (
        <AuthenticatedPage>
            <PointsLedger pointsLedger={(data?.pointsLedgers?.nodes || []).slice()}/>
        </AuthenticatedPage>
    );
}
