import React, {useMemo, useState} from 'react';
import {getPartner, mapBucket} from "./Consumers";
import useAllConsumers from "../../lib/useAllConsumers";

const CSV:React.FC = () => {
    const list = useAllConsumers();

    const [showGenerateButton, setShowGenerateButton] = useState(true);

    const csv = useMemo(() => {
        if (!list || showGenerateButton)
            return '';

        const rows = [
            [
                'Name',
                'Track',
                'Milestone',
                'Sort Order',
                'Bucket',
                'Deal Amount',
                'Email',
                'Phone',
                'Status',
                'City',
                'State',
                'Buy Price Range',
                'Sell Price Range',
                'Timeframe',
                'Service',
                'Created',
                'Referrer',
                'Entry Page',
                'Campaign',
                'Partner',
                'utm_campaign',
                'utm_medium',
                'utm_source',
                'Side',
                'Agent Connected',
                'Agent Connected Email'
            ]
        ];

        list.forEach((data:any) => {
            const consumer = mapFields()(data);

            if (!consumer)
                return;

            const {
                firstName,
                lastName,
                email,
                phone,
                status,
                city,
                state,
                buyPrice,
                sellPrice,
                timeframe,
                date,
                // amexConnected,
                // membershipPaid,
                campaign,
                service,
                // haveAmex,
                // needMortgage,
                deals,
                // inquiry
            } = consumer;

            (deals ?? []).forEach((deal:any) => {
                const bucket = deal ? mapBucket(deal) : '';

                // const lead = (inquiry?.leads?.nodes || []).find((lead:any) => lead.ebDealUniqueId === String(deal?.uniqueId));
                const connectedTo = deal.agent?.account;

                rows.push([
                    `${firstName} ${lastName}`,
                    (deal && deal.track) || '',
                    (deal && deal.milestone) || '',
                    (SORT_ORDER[deal.track] && SORT_ORDER[deal.track][bucket]) || 'Y',
                    bucket,
                    (deal && deal.amount) || 0,
                    email,
                    phone,
                    status,
                    city || '',
                    state || '',
                    buyPrice,
                    sellPrice,
                    timeframe,
                    service || 'Buying',
                    date.toLocaleDateString(),
                    campaign?.referrer || 'direct',
                    campaign?.page || '',
                    campaign?.c || '',
                    getPartner(campaign, date),
                    campaign?.utm_campaign || '',
                    campaign?.utm_medium || '',
                    campaign?.utm_source || '',
                    deal.side,
                    connectedTo ? `${connectedTo?.firstName} ${connectedTo?.lastName}` : '',
                    connectedTo?.email ?? ''
                ]);
            });
        });

        const csv = encodeURIComponent(rows.map(fields => fields.map((field:any) => {
            if (field instanceof Array) {
                const unique = field.filter((value, index, self) => self.indexOf(value) === index);

                field = unique.join(', ');
            }

            if (typeof field === 'string')
                return field ? `"${field.replaceAll('"', '""')}"` : '';
            else
                return field;
        }).join(',')).join('\n'));

        return `data:text/plain;charset=utf-8,${csv}`;
    }, [list, showGenerateButton]);

    if (showGenerateButton)
        return <button onClick={() => setShowGenerateButton(false)}>Generate CSV</button>;

    if (!(csv))
        return <span>Loading...</span>;

    return <a href={csv} download='consumers.csv'>Download CSV</a>;
}

export default CSV;


const mapFields = (filter?:string) => (c:any) => {
    const {account:{nodeId: accountNodeId, firstName, lastName, payments, campaign, id: accountId, suppressMessages, invitations}, providersInArea} = c;
    const email = c.account.email;
    const inquiry = c.inquiries && c.inquiries.nodes && c.inquiries.nodes.length && c.inquiries.nodes[0];
    const phone = c.account.phone;
    const date = new Date(c.createdAt);

    let status = 'unverified';

    if (inquiry && inquiry.detailsConfirmed)
        status = 'app complete';

    status = status.toLowerCase().replace(/_/g, ' ');

    const details = inquiry?.details;
    const buying = details?.buying || null;
    const selling = details?.selling || null;
    const location = (details && details.location && [details.location].map(({label}) => {
        const [city, state] = label.split(', ');
        return {city, state};
    })[0]) || null;
    const service = (details && details.service) || '';
    const haveAmex = (details && details.haveAmex) || '';
    const needMortgage = (details && details.needMortgage) || '';
    const selectedAgent = inquiry && inquiry.details.selectedAgent;
    const {city, state} = location || buying || {};

    const search = filter?.trim().toLowerCase();
    const match = search ? (f?:string) => (f||'').toLowerCase().includes(search) : null;

    if (match) {
        if (search?.length === 2) {
            if (!match(state))
                return null;
        }
        else if (!(
            match(firstName) ||
            match(lastName) ||
            match(email) ||
            match(phone) ||
            match(city)
        )) {
            return null;
        }
    }

    const amexConnected = !!(c && c.vendors && c.vendors.amex && c.vendors.amex.walletId);
    const membershipPaid = !!(payments && payments.nodes && 0 < payments.nodes.length);

    return {
        id: c.id,
        firstName,
        lastName,
        email,
        phone,
        status,
        city,
        state,
        buyPrice: service === 'Selling' ? 'selling' : buying?.price || 'N/A',
        sellPrice: service === 'Buying' ? 'buying' : selling?.price || 'N/A',
        // priceRange: buying?.price || '',
        timeframe: buying && buying.timeframe,
        selectedAgent,
        date,
        amexConnected,
        membershipPaid,
        campaign,
        accountId,
        suppressMessages,
        accountNodeId,
        service,
        haveAmex,
        needMortgage,
        inquiry,
        invitations: invitations?.nodes ?? [],
        providersInArea,
        deals: c.deals?.nodes ?? []
    }
};

const SORT_ORDER:any = {
    'Member Leads': {
        'New': 'A',
        'Warm Lead': 'B',
        'Finding an Agent': 'C',
        'Connecting Listing Agent': 'D',
        'Connecting Buyer Agent': 'D',
        'Dead': 'Z',
        'Already Has Agent': 'Z',
        'Fake': 'Z',
        'Lost Opportunity': 'Z',
        'Not a Good Fit': 'Z',
        'Stop Rcvd': 'Z',
        'Not Interested': 'Z',
        'Strategic Drop': 'Z',
    },
    'Transactions': {
        'Connected Buyers': 'A',
        'Connected Sellers': 'A',
        'In Escrow': 'B',
        'Points Pending': 'C',
        'Closed': 'C',
        'Payment Received': 'C',
        'Test Points Issued': 'C',
        'Test Points Confirmed': 'C',
        'Points Issued': 'D',
        'Fell Through': 'Z',
    }
};