import {useQuery} from "@apollo/client";
import {useEffect, useMemo, useState} from "react";
import localforage from "localforage";
import {ConsumerDataFragment, ConsumersListDocument} from "../generated/graphql/graphql";

localforage.config({name: 'rbn'});

export default function useAllConsumers() {
    const {data} = useQuery(ConsumersListDocument);

    const [consumers, setConsumers] = useState<any[] | undefined>();

    useEffect(() => {
        if (data?.allConsumers?.nodes) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const consumers = data.allConsumers.nodes;

            setConsumers(consumers);

            localforage.setItem('consumersData', consumers)
                .then(() => {
                    // console.log(`${Date.now()}: updated consumersData`)
                })
                .catch(console.error);
        }
        else {
            localforage.getItem('consumersData').then(consumers => {
                if (!consumers)
                    return;

                setConsumers(_consumersData => {
                    if (_consumersData)
                        return _consumersData;
                    else {
                        // console.log(`${Date.now()}: using cached consumersData`);
                        return consumers as ConsumerDataFragment[];
                    }
                });
            });
        }
    }, [data, setConsumers]);

    return consumers;
}

export function useAllConsumerDeals(): any[] {
    const consumers = useAllConsumers();

    return useMemo(() => {
        const deals:any[] = [];

        consumers?.forEach(consumer => {
            if (!consumer?.deals)
                return;

            // const added:Array<ConsumerDataFragment> = [];

            (consumer.deals?.nodes ?? []).forEach((deal: any) => {
                const newDeal = {
                    ...consumer,
                    deal
                };

                deals.push(newDeal);
                // added.push(newDeal);
            });

            // if (1 < added.length)
            //     console.log({consumer, added});
        });

        return deals;
    }, [consumers])
}