import React, {ChangeEventHandler, useEffect, useState} from 'react';
import styled from "styled-components";

import {useMutation, useQuery} from "@apollo/client";
import Spinner from "../Spinner";
import {Button, Buttons, Form, Input, Select} from "../form";
import Overlay from "../Overlay";
import useViewer from "../../lib/use-viewer";
import {getPartner} from "../../pages/Consumers/Consumers";
import {AccountByIdDocument, AdminUpdateAccountDocument} from "../../generated/graphql/graphql";

interface EditAccountProps {
    accountId: any,
    close: () => void
}

const EditAccount:React.FC<EditAccountProps> = ({accountId, close}) => {
    const { viewer } = useViewer();

    const {data, loading} = useQuery(AccountByIdDocument, {
        variables: {
            id: accountId||''
        }
    });
    const [updateAccount] = useMutation(AdminUpdateAccountDocument)

    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [campaign, setCampaign] = useState<any>();
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const account = data?.accountById;

        if (!account)
            return;

        setFirstName(account.firstName);
        setLastName(account.lastName);

        const email = account.contacts?.nodes?.find((contact:any) => contact.type === 'email')?.value.address;
        if (email)
            setEmail(email);

        const phone = account.contacts?.nodes?.find((contact:any) => contact.type === 'phone')?.value.number;
        if (phone)
            setPhone(phone);

        setCampaign(account.campaign ?? {});
    }, [data, setFirstName, setLastName, setEmail, setPhone]);

    // const [details, setDetails] = useState<any>();

    const closeHandler = (e?:any) => {
        if (typeof e?.preventDefault === 'function') e.preventDefault();

        if (e.target === e.currentTarget)
            close();
    }

    const [linkBehavior, setLinkBehavior] = useState(window.localStorage.getItem('noTabs') ? 'same-tab' : 'new-tab');
    const updateLinkBehavior: ChangeEventHandler<HTMLSelectElement> = e => setLinkBehavior(e.target.value);

    const [loginSite, setLoginSite] = useState(window.localStorage.getItem('staging') ? 'staging' : 'live');
    const updateLoginSite: ChangeEventHandler<HTMLSelectElement> = e => setLoginSite(e.target.value);

    const saveHandler = (e?:any) => {
        if (typeof e?.preventDefault === 'function') e.preventDefault();

        const data: any = {
            firstName,
            lastName,
            email,
            phone,
            campaign
        };

        if (password)
            data.password = password;

        setSaving(true);

        updateAccount({
            variables: {
                input: {
                    accountId,
                    data
                }
            }
        })
            .then(() => {
                if (linkBehavior === 'new-tab')
                    window.localStorage.removeItem('noTabs');
                else
                    window.localStorage.setItem('noTabs', 'true');

                if (loginSite === 'live')
                    window.localStorage.removeItem('staging');
                else
                    window.localStorage.setItem('staging', 'true');

                window.dispatchEvent(new CustomEvent("stagingFlagUpdated"));
            })
            .then(close);
    }

    const changeHandler = (data:any) => {
        const {name, value} = data.target;

        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'password':
                setPassword(value.trim());
                break;
            case 'confirmPassword':
                setConfirmPassword(value.trim());
                break;
            case 'partner':
                setCampaign((campaign: any) => ({...campaign, partner: value}));
                break;
        }
    }

    const invalidPassword = password !== confirmPassword;

    const usersAccount = viewer?.id === accountId;

    const partner = getPartner(campaign, new Date(data?.accountById?.createdAt ?? Date.now()));

    return (
        <Overlay>
            <Popup>{loading ? <Loading/> : (
                <StyledForm>
                    <h2>Edit Account</h2>
                    <Input label="First Name" value={firstName} name="firstName" onChange={changeHandler}/>
                    <Input label="Last Name" value={lastName} name="lastName" onChange={changeHandler}/>
                    <Input label="Email Address" value={email} name="email" type="email" onChange={changeHandler}/>
                    <Input label="Phone Number" value={phone} name="phone" type="tel" onChange={changeHandler}/>
                    <Input label="New Password" value={password} name="password" onChange={changeHandler} className={invalidPassword ? 'invalid' : ''}/>
                    <Input label="Confirm Password" value={confirmPassword} name="confirmPassword" onChange={changeHandler} className={invalidPassword ? 'invalid' : ''}/>
                    {usersAccount ? (
                        <>
                            <Select label="Link Behavior" value={linkBehavior} onChange={updateLinkBehavior}>
                                <option value="new-tab">Open In New Tab</option>
                                <option value="same-tab">Open In Same Tab</option>
                            </Select>
                            <Select label="Login Site" value={loginSite} onChange={updateLoginSite}>
                                <option value="live">Live</option>
                                <option value="staging">Staging</option>
                            </Select>
                        </>
                    ) : (
                        <Input label="Partner" value={partner ?? ''} name="partner" onChange={changeHandler}/>
                    )}
                    <Buttons>
                        <Button onClick={closeHandler} variation="transparent" disabled={saving}>Cancel</Button>
                        <Button onClick={saveHandler} disabled={saving || invalidPassword}>{saving ? 'Saving...' : 'Save'}</Button>
                    </Buttons>
                </StyledForm>
            )}</Popup>
        </Overlay>
    )
};

export default EditAccount;

const StyledForm = styled(Form)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: .5rem;
    
    h2 {
        margin-top: 0;
    }
    
    h2, ${Buttons} {
        grid-column: span 2;
    }
`;

const Popup = styled.div`
    background: #fff;
    padding: 1rem;
`;

const Loading = styled.div.attrs({children:<Spinner/>})`
    padding: 2rem;
`;