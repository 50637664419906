const gitBranch = process.env.REACT_APP_GIT_BRANCH;

function getVersion() {

    let frontend;

    // determine if this script was served from localhost
    try {
        // throw an error to get the stack trace
        throw Error();
    }
    catch (e: any) {
        const stackLines = e.stack.split('\n');
        const callerLine = stackLines[stackLines.length - 1];
        const match = callerLine.match(/https?:\/\/([^/:]*)/);

        if (match && match[1] === 'localhost')
            frontend = 'local';
    }

    if (!frontend) {
        switch (document.location.hostname) {
            case 'dev.admin.joinrbn.com':
            case 'dev.admin.rbnrewards.com':
                frontend = 'dev';
                break;
            case 'staging.joinrbn.com':
            case 'staging.rbnrewards.com':
                frontend = 'staging';
                break;
            case 'admin.joinrbn.com':
            case 'admin.rbnrewards.com':
                frontend = 'production';
                break;
            case 'localhost':
                frontend = 'local';
                break;
            default:
                console.error(`unknown hostname ${document.location.hostname}`);
                frontend = 'local';
        }
    }

    let backend = frontend;

    if (!sessionStorage.getItem('localAPI') && gitBranch) {
        switch (gitBranch) {
            case 'master':
            case 'main':
                backend = 'production';
                break;
            case 'staging':
                backend = 'staging';
                break;
            default:
                if (gitBranch !== 'dev')
                    console.warn(`current git branch: ${gitBranch}`);

                backend = 'dev';
                break;
        }
    }

    if (!['production'].includes(frontend) || frontend === 'local') {
        const color = ['local', backend].includes(frontend) ? '#090' : '#900';
        console.log(`%c
${ gitBranch ? `  branch: ${gitBranch}\n` : '' } backend: ${backend}
frontend: ${frontend}
`, `font-size: 24px; font-weight: bold; color: ${color}; margin: 0;`);
    }

    if (frontend === 'local')
        return backend;

    return frontend;
}

export const version = getVersion();

if (['local', 'dev'].includes(version))
    document.getElementById('favicon')?.setAttribute('href', '/favicon-dev.png');