import styled from "styled-components";

export const PhotoDiv = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
`;

interface Props {
    url: string;
}

export const Photo = (props: Props) => {
    const url = props.url.match(/^\//) ? `https://api.rbnrewards.com${props.url}` : props.url;

    return (
        <PhotoDiv style={{backgroundImage: `url("${url}")`}}/>
    );
}