import styled from "styled-components";
import SelectedPresented from "../../components/SelectedPresented";
import {Maybe, PotentialProviderFragment} from "../../generated/graphql/graphql";

interface Props {
    agent: Maybe<PotentialProviderFragment> | undefined
}

export const AgentPresentedSelected: React.FC<Props> = ({agent}) => {
    return (
        <Container>
            <SelectedPresented {...agent as any}/>
        </Container>
    );
};

const Container = styled.span`
    & > div > div {
        transform: translateX(-25%);
    }
`;

