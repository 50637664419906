import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

import {propFn} from "../../lib/theme";

const property = propFn('button');

// TODO: had to add !important because of bootstrap :-P

interface Props extends HTMLAttributes<HTMLButtonElement> {
    disabled?: boolean
    variation?: string;
    to?: string;
}

const Button = styled(({children, ...props}: Props) => (
    <button {...props}>{children}</button>
)).attrs(({className}) => ({
    className: `${className} button`
}))`
    display: inline-block;
    text-decoration: none !important;
    white-space: nowrap;
    line-height: 30px;
    margin: 0;
    padding: 10px 20px;
    width: auto;
    min-width: 160px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;

    font-size: ${property('font-size', '16px')};
    color: ${({disabled,theme:{accent}}) => disabled ? accent : property('color')} !important;
    background-color: ${({disabled}) => disabled ? '#ccc' : property('background')};
    border: ${property('border', 'none')};
    
    transition: .1s all;
    
    border-radius: 2em;
    
    @media (max-width: 400px) {
      min-width: 100px;
    }
`;

export default Button;