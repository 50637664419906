import {Button, Buttons, Input, TextArea} from "../../components/form";
import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import styled from "styled-components";
import {PointsLedgerAdjustDocument} from "../../generated/graphql/graphql";

interface Props {
    consumer?: any;
}

const PointsLedger = ({consumer}: Props) => {
    const [manualIssuePoints, setManualIssuePoints] = useState('0');
    const [manualIssueNote, setManualIssueNote] = useState('');
    const [issuingManualPoints, setIssuingManualPoints] = useState(false);
    const [pointsLedgerAdjust] = useMutation(PointsLedgerAdjustDocument, { variables: {
            input: {
                accountId: consumer?.account?.id,
                quantity: parseInt(manualIssuePoints),
                note: manualIssueNote
            }
        }});
    const manualIssue = () => {
        setIssuingManualPoints(true);

        pointsLedgerAdjust()
            .then(() => {
                setManualIssuePoints('0');
                setManualIssueNote('');
            })
            .finally(() => {
                setIssuingManualPoints(false);
            });
    }

    const changeHandler = (fn:any) => (e:any) => {
        fn(e.target.value);
    }

    return (
        <PointsLedgerContainer>
            <Table>
                {/*['Date', 'Type', 'Points', 'Admin', 'Note'].map((header) => (<Heading key={header}>{header}</Heading>))*/}
                {consumer?.account?.pointsLedger?.nodes?.length ? <Separator/> : null}
                {consumer?.account?.pointsLedger?.nodes?.map((ledger:any) => {
                    const switchfly = ledger.note?.match(/switchfly/i);

                    return (
                        <React.Fragment key={ledger.id}>
                            <div>{new Date(ledger.createdAt).toLocaleDateString()}</div>
                            <div>{ledger.entryType}</div>
                            <div>{ledger.quantity.toLocaleString()}</div>
                            <div>{ledger.accountByCreatedBy.firstName}{!switchfly && ` ${ledger.accountByCreatedBy.lastName}`}</div>
                            <div title={JSON.stringify(ledger.data)}>{switchfly ? `Booking #${ledger.data.bookingId}` : ledger.note}</div>
                            {ledger.data?.bookingId && ledger.data?.metadata && <SwitchflyLedgerDetail data={ledger.data}/>}
                            {ledger.data?.bookingId && ledger.data?.cancelledProduct && <SwitchflyRefundDetail data={ledger.data}/>}
                            <Separator/>
                        </React.Fragment>
                    );
                })}
            </Table>
            <h3>Manual Entry</h3>
            <Input label="Points" value={manualIssuePoints} onChange={changeHandler(setManualIssuePoints)} type="num" required={true}/>
            <TextArea label="Note" value={manualIssueNote} onChange={changeHandler(setManualIssueNote)} required={true}/>
            <Buttons><Button onClick={manualIssue} disabled={issuingManualPoints || !(manualIssuePoints && manualIssueNote)}>{issuingManualPoints ? 'Sending...' : (parseInt(manualIssuePoints) < 0 ? 'Deduct RBN Rewards Points' : 'Issue RBN Rewards Points')}</Button></Buttons>
        </PointsLedgerContainer>
    )
}

export default PointsLedger;

// const Heading = styled.div`
//     font-weight: bold;
//     color: rgba(0,0,0,.75);
// `;

const Separator = styled.div`
    grid-column: 1 / -1;
    border-bottom: 4px dotted rgba(0,0,0,.25);
    margin: 1em 0;
`;

const PointsLedgerContainer = styled.div`
`;

export const SwitchflyLedgerDetail = styled(({data, className}: any) => {
    const total: string[] = [];

    if (0 < data.points)
        total.push(`${data.points.toLocaleString()} RBN Rewards Points`);

    const payments = (data.metadata?.payments || [])
        .reduce((acc: {[currency: string]: number}, payment:any) => {
            if (!acc[payment.currency])
                acc[payment.currency] = payment.amount;
            else
                acc[payment.currency] += payment.amount;

            return acc;
        }, {} as {[currency: string]: number});

    Object.entries(payments).forEach(([currency, _amount]) => {
        const amount = _amount as number;

        total.push(`${amount.toLocaleString('en-US', { style: 'currency', currency })}`);
    });

    return (
        <div className={className}>
            {data?.metadata?.products.map((product:any, i:number) => (
                <LedgerDetailLineItem key={i} product={product}/>
            ))}
            <Total>Total Payment: {total.join(' + ')}</Total>
        </div>
    )
})`
`;

export const SwitchflyRefundDetail = styled(({data, className}: any) => {
    const total: string[] = [];

    if (0 < data.pointsToRefund)
        total.push(`${data.pointsToRefund.toLocaleString()} RBN Rewards Points`);

    if (data.cashToRefund)
        total.push(`${data.cashToRefund.toLocaleString('en-US', { style: 'currency', currency: data.currency })}`);

    let type = data.cancelledProduct.type;
    switch (type) {
        case 'AIR':
            type = `Flight`;
            break;
        case 'CAR':
            type = 'Car Rental';
            break;
        case 'ROOM':
            type = 'Hotel';
            break;
        case 'ACTIVITY':
            type = 'Activity';
            break;
    }

    return (
        <div className={className}>
            <LedgerDetailLineItem product={data.cancelledProduct} points={data.pointsToRefund}/>
            <Total>Refund: {total.join(' + ')}</Total>
        </div>
    )
})`
`;

const Table = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    
    ${SwitchflyLedgerDetail}, ${SwitchflyRefundDetail} {
        grid-column: 1 / -1;
        padding: 1rem 0 0 4rem;
    }
`;

const Total = styled.div`
    margin-top: .5em;
    text-align: right;
`;

const LedgerDetailLineItem = styled(({product, className, points}: any) => {
    let type = product.type;
    let detail:any = product.description;

    switch (type) {
        case 'AIR':
            type = `Flight`;
            if (product.attributes)
                detail = <FlightDetail legs={product.attributes.legs}/>;
            break;
        case 'CAR':
            type = 'Car Rental';
            if (product.attributes)
                detail = <CarRentalDetail rental={product.attributes} description={product.description}/>;
            break;
        case 'ROOM':
            type = 'Hotel';
            if (product.attributes)
                detail = <HotelDetail hotel={product.attributes}/>;
            break;
        case 'ACTIVITY':
            type = 'Activity';
            if (product.attributes)
                detail = <ActivityDetail activity={product.attributes}/>;
            break;
    }

    return (
        <div className={className}>
            <div>{type}</div>
            <div>{detail}</div>
            <div>{(points || product.pointsAmount).toLocaleString()} Points</div>
        </div>
    );
})`
    display: grid;
    grid-template-columns: 7rem 1fr auto;
    padding: .25em .5em;
    border-bottom: 1px solid #0a7bc5;
    
    &:first-child {
        margin-top: 0;
        border-top: 1px solid #0a7bc5;
    }
    
    & > :last-child {
        text-align: right;
    }
`;

const FlightDetail = styled(({legs, className}: any) => (
    <div className={className}>
        {legs.map((flight:any, i:number) => {
            const flightNumbers = flight.airlineCodes.map((airline:any, i:number) => `#${airline}${flight.flightNumbers[i]}`).join(', ');
            return (
                <React.Fragment key={i}>
                    <div>{flight.departureDate}</div>
                    <div>{flight.departureAirportCode}-{flight.arrivalAirportCode}</div>
                    <div>{flightNumbers}</div>
                    <div>&nbsp;</div>
                </React.Fragment>
            );
        })}
    </div>
))`
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    grid-column-gap: 1em;
`;

const CarRentalDetail = styled(({rental, description, className}: any) => (
    <div className={className}>
        <div>{rental.companyName} - {description}</div>
        <div>{rental.pickUpDate} {rental.pickupLocation} - {rental.dropOffDate} {rental.dropOffLocation}</div>
    </div>
))`
`;

const HotelDetail = styled(({hotel, className}: any) => (
    <div className={className}>
        <div>{hotel.hotelName}, {hotel.hotelCity}, {hotel.hotelCountry}</div>
        <div>{hotel.checkIn} - {hotel.checkOut}</div>
    </div>
))`
`;

const ActivityDetail = styled(({activity, className}: any) => (
    <div className={className}>
        <div>{activity.activityName}</div>
        <div>{activity.scheduledDate} {activity.tourDuration}</div>
    </div>
))`
`;