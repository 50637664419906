import React from 'react';
import styled from 'styled-components'

import Header from './Header'

interface HeadingProps {
    image: string,
    text: string,
    className?: string
}

export interface Props {
    title?: string,
    headerBackground?: string,
    fullWidth?: boolean,
    backgroundColor?: string|undefined,
    backgroundImage?: string|undefined,
    heading?: HeadingProps,
    padding?: string,
    [key:string]: any
}

const Heading = styled(({text, className}:HeadingProps) => (
    <div className={className}>
        {text}
    </div>
))`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({image}) => `url(${image})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  
  color: #fff;
  font-size: 36px;
`;

interface ContentProps {
    className?: string;
    children?: any;
    fullWidth?: boolean;
    background?: string;
    backgroundImage?: string;
    padding?: string;
}
const Content = styled(({className, children}: ContentProps) => {
    return (
        <section className={className}>
            <article>
                {children}
            </article>
        </section>
    )
})`
    display: flex;
    flex-direction: column;
    align-items: ${({fullWidth=false}) => fullWidth ? 'flex-start' : 'center'};
    flex-grow: 1;
    background: ${({background}) => background};
    background-image: ${({backgroundImage}) => `url(${backgroundImage||''})`};
    background-position: top center;
    background-size: cover;

    & > article {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: ${({fullWidth=false, theme:{width}}) => fullWidth ? '100%' : width};
        min-width: ${({fullWidth=false}) => fullWidth ? '100%' : '900px'};
        margin: 0 auto;
        padding: ${({padding}) => padding ? padding : '1rem 0'};
        
        @media(max-width: 900px) {
          min-width: auto;
        }
    }
    
    & > article > :first-child {
        margin-top: 0;
    }
`;

const Page = styled(({className, title, heading, ...props}:Props) => {
    const {headerBackground='#0d3f88'} = props;

    return (
        <main className={className}>
            <Header title={title} background={headerBackground}/>
            {heading && <Heading {...heading}/>}
            <Content {...props}/>
        </main>
    );
})`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    
    background-color: ${({theme:{page:{background}}, backgroundColor}) => backgroundColor || background};

`;

export default Page