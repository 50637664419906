import styled from "styled-components";

interface Props {
    selectedBy?: string[],
    presentedTo?: string[]
}

const Popup = styled.div`
    position: absolute;
    padding: 0.5rem;
    top: 100%;
    background: #fff;
    box-shadow: 1px 1px 6px rgba(0,0,0,.5);
    
    font-size: 12px;
    
    display: none;
    grid-template-columns: auto auto;
    gap: .25em 1em;
    z-index: 10000;
`;

const Container = styled.div`
    &:hover ${Popup} {
        display: grid;
    }
`;

const SelectedPresented = ({ selectedBy, presentedTo}: Props) => {
    const selectedByLength = selectedBy?.length ?? 0;
    const presentedToLength = presentedTo?.length ?? 0;

    const usePopup = 0 < selectedByLength + presentedToLength;

    return (
        <Container>
            {selectedByLength}:{presentedToLength}
            {usePopup && <Popup>
                <strong>Selected By</strong>
                <strong>Presented To</strong>
                <div>
                    {selectedBy?.map((cell, i) => (
                        <div key={i}>{cell}</div>
                    ))}
                </div>
                <div>
                    {presentedTo?.map((cell, i) => (
                        <div key={i}>{cell}</div>
                    ))}
                </div>
            </Popup>}
        </Container>
    )
}

export default SelectedPresented;