import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import AuthenticatedPage from '../../components/AuthenticatedPage';
import {AgentPresentedSelected} from "./AgentPresentedSelected";
import styled from 'styled-components';
import { Button, Buttons } from '../../components/form';
import {Photo, PhotoDiv} from './Photo';
import { getPriceInfo, SearchLink } from './AgentFinder';
import { TransactionInfo } from './TransactionInfo';
import {
    CreateReviewDocument,
    Provider,
    ProviderByIdDocument,
    ProviderTransaction
} from "../../generated/graphql/graphql";
import {loginAsHandler} from "../../lib/login-as";

const StyledInput = styled.input`
    width: 35px;
    height: 35px;
    border: 1px solid #666;
`;

const StyledTextArea = styled.textarea`
    border: 1px solid #666;
`;

const ProviderDealWrapper = styled.div`
    padding: 2rem;
    min-width: 700px;
    background: #fff;
    
    ${Buttons} {
        margin: 2rem 0 0;
    }
    
    textarea {
        min-height: 70px;
    }
`;

const ProviderDealHeading = styled.div`
    display: flex;
    column-gap: 1rem;
    
    ${PhotoDiv} {
        width: 150px;
        height: 150px;
    }
    
    h2 {
        margin: 0;
    }
`;

const ProviderDealLinks = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Ratings = styled.div`
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const AddReview = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const FieldWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`

const ReviewsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const ReviewBox = styled.div`
    border: 1px solid darkgrey;
    margin-top: 1rem;
    border-radius: 8px;
    padding: 0 0.5rem;
`

const AgentProfile: React.FC = () => {
    const { id } = useParams();
    const [showReviewFields, setShowReviewFields] = React.useState(false);
    const { data } = useQuery(ProviderByIdDocument, {
        variables: { id },
    });

    const [createReview] = useMutation(CreateReviewDocument);
    const provider = data?.providerById;

    const [review, setReview] = React.useState({
        providerId: id,
        reviewer: '', 
        ratingCommunication: 0, 
        ratingResponsiveness: 0, 
        ratingLocalKnowledge: 0, 
        wouldUseAgain: false, 
        comment: ''
    });

    const {city, state} = (provider?.info?.location) || {city:'', state:''};

    const priceInfo = getPriceInfo((provider?.transactions?.nodes ?? []) as ProviderTransaction[]);

    const handleReviewFields = () => { 
        setShowReviewFields(!showReviewFields)
        setReview({
            providerId: id,
            reviewer: '', 
            ratingCommunication: 0, 
            ratingResponsiveness: 0,
            ratingLocalKnowledge: 0, 
            wouldUseAgain: false, 
            comment: ''
        }) 
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value, type} = e.target;
        if(name === 'wouldUseAgain') {
            setReview({...review, [name]: value === 'true'})
            return;
        }
        setReview({...review, [name]: type === 'number' ? value ? Number(value): undefined : value})
    }

    const handleSubmit = async () => {
        try {
            await createReview({
                variables: {
                    input: {review}
                }
            });
            setShowReviewFields(false);
            setReview({
                providerId: id,
                reviewer: '', 
                ratingCommunication: 0, 
                ratingResponsiveness: 0,
                ratingLocalKnowledge: 0, 
                wouldUseAgain: false, 
                comment: ''
            })
        } catch (error) {
            console.error('Error creating review:', error);
        }
    }

    return (
        <AuthenticatedPage title={'Agent Profile'} fullWidth={true} padding="0 0 1rem">
            <ProviderDealWrapper>
                <ProviderDealHeading>
                    <Photo url={provider?.image?.url || ''}/>
                    <div>
                        <h2><a href={`/providers/${provider?.id}`} onClick={loginAsHandler(provider?.account)}>{provider?.name}</a></h2>
                        <ProviderDealLinks>
                            {provider?.engagebay?.id && <a href={`https://joinrbn.engagebay.com/home#list/0/subscriber/${provider?.engagebay?.id}`} target="engagebay">EB Link</a>}
                            <SearchLink term={`${provider?.name} real estate ${city} ${state}`}>Google</SearchLink>
                        </ProviderDealLinks>
                        <div>{city && `${city}, ${state}`}</div>
                        {priceInfo.count  && <TransactionInfo provider={provider}/>}
                        {provider && <div><AgentPresentedSelected agent={provider as Provider}/> &nbsp;{provider?.status?.status || ''}</div>}
                    </div>
                </ProviderDealHeading>

                <Ratings>
                    <label>Rating:</label>
                    <div>
                        {provider?.rating?.toFixed(1) ?? 'N/A'}
                    </div>
                    <label>Communication Skills:</label>
                    <div>
                        {provider?.ratingCommunication?.toFixed(1) ?? 'N/A'}
                    </div>
                    <label>Local Market Knowledge:</label>
                    <div>
                        {provider?.ratingLocalKnowledge?.toFixed(1) ?? 'N/A'}
                    </div>
                    <label>Responsiveness:</label>
                    <div>
                        {provider?.ratingResponsiveness?.toFixed(1) ?? 'N/A'}
                    </div>
                </Ratings>
                {!showReviewFields && <Buttons>
                    <Button onClick={handleReviewFields}>Add Review</Button>        
                </Buttons>}
                {showReviewFields && 
                <>
                <AddReview>
                    <FieldWrapper>
                        <label>Reviewer:</label>
                        <StyledInput type="text" value={review.reviewer} onChange={handleChange} name='reviewer' placeholder="Reviewer" style={{width: 'auto'}}/>
                    </FieldWrapper>
                    <FieldWrapper>
                        <label>How would you rate your RBN Participating Agent's communication skills?</label>
                        <span><StyledInput type="number" min="1" max="10" value={review.ratingCommunication} onChange={handleChange} name='ratingCommunication' placeholder=""/>/10</span>
                    </FieldWrapper>
                    <FieldWrapper>
                        <label>How would you rate your RBN Participating Agent's responsiveness?</label>
                        <span><StyledInput type="number" min="1" max="10" value={review.ratingResponsiveness} onChange={handleChange} name='ratingResponsiveness' placeholder=""/>/10</span>
                    </FieldWrapper>
                    <FieldWrapper>
                        <label>How would you rate your RBN Participating Agent's local market knowledge?</label>
                        <span><StyledInput type="number" min="1" max="10" value={review.ratingLocalKnowledge} onChange={handleChange} name='ratingLocalKnowledge' placeholder=""/>/10</span>
                    </FieldWrapper>
                    <FieldWrapper> 
                        <label>Would you use your RBN Participating Agent again?</label>
                        <span style={{display: 'flex'}}>
                        <div>
                            <StyledInput type="radio" id="yes" name="wouldUseAgain" value={'true'} style={{width: 'auto', height:'auto'}} onChange={handleChange}/>
                            <label htmlFor="yes">Yes</label>
                        </div>

                        <div>
                            <StyledInput type="radio" id="no" name="wouldUseAgain" value={'false'} style={{width: 'auto', height:'auto'}} onChange={handleChange}/>
                            <label htmlFor="no">No</label>
                        </div>
                        </span>
                    </FieldWrapper>
                    <FieldWrapper>
                        <label>Tell us about your experience with your RBN Participating Agent</label>
                        <StyledTextArea placeholder="Comment" value={review.comment} name='comment' onChange={handleChange}/>
                    </FieldWrapper>
                </AddReview>
                <Buttons>
                    <Button onClick={handleReviewFields}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </Buttons>
                </>
                }
                <ReviewsWrapper>
                    {provider?.reviews?.nodes?.map((review) => {
                        return (
                            <ReviewBox key={review.nodeId}>
                                <h2>{review.reviewer}</h2>
                                <p>How would you rate your RBN Participating Agent's communication skills?  : {review.ratingCommunication ? `${review.ratingCommunication}/10` : 'NA'}</p>
                                <p>How would you rate your RBN Participating Agent's responsiveness? : {review.ratingResponsiveness ? `${review.ratingResponsiveness}/10` : 'NA'}</p>
                                <p>How would you rate your RBN Participating Agent's local market knowledge? : {review.ratingLocalKnowledge ? `${review.ratingLocalKnowledge}/10` : 'NA'}</p>
                                <p>Would you use your RBN Participating Agent again?: {review.wouldUseAgain ? 'Yes' : 'No'}</p>
                                <h3>Experience:</h3>
                                <p>{review.comment}</p>
                            </ReviewBox>
                        )
                    })}
                </ReviewsWrapper>
            </ProviderDealWrapper>
        </AuthenticatedPage>
    );
};

export default AgentProfile;