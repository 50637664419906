import {apolloClient, JWT_KEY} from "../config";
import {FormEvent, SyntheticEvent, useCallback, useState} from "react";
import useRedirect from "./use-redirect";
import useFields from "./use-fields";
import {useMutation} from "@apollo/client";
import {LoginDocument, ResetPasswordDocument, SetPasswordDocument} from "../generated/graphql/graphql";

export const saveJWT = (jwt:string) => {
    window.localStorage.setItem(JWT_KEY, jwt);

    return apolloClient.resetStore();
};

export const getJWT = () => {
    return window.localStorage.getItem(JWT_KEY);
};

export const clearJWT = () => {
    return localStorage.removeItem(JWT_KEY);
};

export function useClearStorage(): () => Promise<void> {
    return useCallback(() => {
        window.localStorage.removeItem(JWT_KEY)
        window.sessionStorage.clear();

        return apolloClient.resetStore()
            .then(() => {});
    }, []);
}

export function useLogout(): (event?:SyntheticEvent) => void {
    const redirect = useRedirect();
    const clearStorage = useClearStorage();

    return (event?:SyntheticEvent) => {
        event && event.preventDefault();

        clearStorage()
            .then(() => redirect('/login'));
    };
}

const LOGIN_REDIRECT_KEY = 'login-redirect';

export function useRedirectToLogin(): () => void {
    const redirect = useRedirect();

    return useCallback(() => {
        const path = redirect('/login');

        // save the requested path so we can return after login
        window.sessionStorage.setItem(LOGIN_REDIRECT_KEY, path);
    }, [redirect]);
}

export function useLoginRedirect(): () => void {
    const redirect = useRedirect();

    return () => {
        const path = window.sessionStorage.getItem(LOGIN_REDIRECT_KEY);

        if (path)
            window.sessionStorage.removeItem(LOGIN_REDIRECT_KEY);

        redirect(path || '/dashboard');
    }
}

interface LoginFields {
    email: string,
    password: string
}

interface UseLogin {
    login: (event?:FormEvent) => Promise<void>,
    fields: LoginFields,
    updateField: (value:string) => void,
    error?: string
}

export function useLogin(): UseLogin {
    const {fields, updateField} = useFields(['email', 'password']);
    const [loginMutation] = useMutation(LoginDocument, {variables: {input: fields}});
    const loginRedirect = useLoginRedirect();
    const [error, setError] = useState('');

    const login = (event?:FormEvent) => {
        event && event.preventDefault();

        if (!fields.email?.match(/@joinrbn.com$/)) {
            setError('Invalid email or password.');
            return Promise.reject();
        }

        return loginMutation()
            .then(result => {
                if (!result)
                    throw new Error('Server error, please try again later.');

                return result;
            })
            .then(({data}) => data && data.login && data.login.jwtToken)
            .then((jwt) => {
                if (!jwt)
                    throw new Error('Invalid email or password.');

                return saveJWT(jwt);
            })
            .then(loginRedirect)
            .catch((error:Error) => {
                if (setError)
                    setError(error.message);
                else
                    throw error;
            });
    };

    return {login, fields, updateField, error};
}

interface ResetPasswordFields {
    email: string
}

interface ResetPassword {
    resetPassword: (event?:FormEvent) => Promise<void>,
    fields: ResetPasswordFields,
    updateField: (value:string) => void,
    error?: string
}

export function useResetPassword(): ResetPassword {
    const {fields, updateField} = useFields(['email']);
    const [resetPasswordMutation] = useMutation(ResetPasswordDocument, {variables: {input: fields}});
    const [error, setError] = useState('');

    const resetPassword = (event?:FormEvent) => {
        event && event.preventDefault();

        return resetPasswordMutation()
            .then(result => {
                if (!result)
                    throw new Error('Server error, please try again later.');
            })
            .catch((error:Error) => {
                if (setError)
                    setError(error.message);
                else
                    throw error;
            });
    };

    return {resetPassword, fields, updateField, error};
}

interface SetPasswordFields {
    password: string,
    confirmPassword: string
}

interface UseSetPassword {
    setPassword: (event?:FormEvent) => Promise<void>,
    fields: SetPasswordFields,
    updateField: (value:string) => void,
    error?: string
}

export function useSetPassword(): UseSetPassword {
    const {fields, updateField} = useFields(['password', 'confirmPassword']);
    const [setPasswordMutation] = useMutation(SetPasswordDocument, {
        variables: {
            input: {
                password: fields.password
            }
        }
    });
    const [error, setError] = useState('');

    const setPassword = (event?:FormEvent) => {
        event && event.preventDefault();

        if (fields.password !== fields.confirmPassword) {
            setError('Password and Confirm Password must be the same');
            return Promise.reject();
        }

        return setPasswordMutation()
            .then(result => {
                if (!result)
                    throw new Error('Server error, please try again later.');
            })
            .catch((error:Error) => {
                if (setError)
                    setError(error.message);
                else
                    throw error;
            });
    };

    return {setPassword, fields, updateField, error};
}
