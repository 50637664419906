import styled from "styled-components";
import React, {ChangeEventHandler, MouseEventHandler, useEffect, useMemo, useState} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import {createPortal} from "react-dom";
import Detail from "./Detail";
import CSV from "./CSV";


interface Props {
    pointsLedger: any;
}

export default function PointsLedger({ pointsLedger}: Props) {
    const [nameFilter, setNameFilter] = useState('');
    const [typeList, setTypeList] = useState<string[]>([]);
    const [typeFilter, setTypeFilter] = useState('');
    const [adminList, setAdminList] = useState<string[]>([]);
    const [adminFilter, setAdminFilter] = useState('');
    const [sort, setSort] = useState<'date'|'points'>('date');
    const [direction, setDirection] = useState<'▼'|'▲'>('▼');

    useEffect(() => {
        const types = new Set<string>();
        const adminList = new Set<string>();

        pointsLedger.forEach((ledger:any) => {
            types.add(ledger.entryType);
            adminList.add(ledger.accountByCreatedBy.firstName + ' ' + ledger.accountByCreatedBy.lastName);
        });

        setTypeList(Array.from(types).sort());
        setAdminList(Array.from(adminList).sort());
    }, [pointsLedger]);

    const filteredList = useMemo(() => {
        return pointsLedger.slice()
            .filter((ledger:any) => {
                if (nameFilter && !(
                    ledger.account.firstName.toLowerCase().includes(nameFilter.toLowerCase())
                    || (ledger.account.lastName.toLowerCase().includes(nameFilter.toLowerCase()))
                )) {
                    return false;
                }

                if (typeFilter && ledger.entryType !== typeFilter) {
                    return false;
                }

                if (adminFilter && ledger.accountByCreatedBy.firstName + ' ' + ledger.accountByCreatedBy.lastName !== adminFilter) {
                    return false;
                }

                return true;
            })
            .sort((a:any, b:any) => {
                if (sort === 'date') {
                    return (direction === '▲' ? 1 : -1) * a.createdAt.localeCompare(b.createdAt);
                }

                return (direction === '▲' ? 1 : -1) * (a.quantity - b.quantity);
            })
    }, [pointsLedger, nameFilter, typeFilter, adminFilter, sort, direction]);

    const placeholder = `Search ${filteredList.length} ${filteredList.length === 1 ? 'entry' : 'entries'}...`;

    const updateSort = (field: 'date' | 'points'): MouseEventHandler<HTMLElement> => e => {
        e.preventDefault();

        if (sort === field) {
            setDirection(direction === '▼' ? '▲' : '▼');
        } else {
            setSort(field);
            setDirection('▼');
        }
    }

    return (
        <Grid>
            <Heading>
                <Cell>
                    <strong onMouseDown={updateSort('date')}>Date{sort === 'date' ? ` ${direction}` : ''}</strong>
                </Cell>
                <Cell>
                    <strong>Consumer</strong>
                    <Filter value={nameFilter} setter={setNameFilter} placeholder={placeholder}/>
                </Cell>
                <Cell>
                    <strong>Type</strong>
                    <SelectFilter value={typeFilter} setter={setTypeFilter} options={typeList}/>
                </Cell>
                <Cell>
                    <strong onMouseDown={updateSort('points')}>Points{sort === 'points' ? ` ${direction}` : ''}</strong>
                </Cell>
                <Cell>
                    <strong>Admin</strong>
                    <SelectFilter value={adminFilter} setter={setAdminFilter} options={adminList}/>
                </Cell>
                <Cell>
                    <strong>Note <CSV data={pointsLedger}/></strong>
                </Cell>
            </Heading>
            <AutoSizer>
                {({height, width}: {height: number, width: number}) => (
                    <List
                        height={height - 50}
                        itemCount={filteredList.length}
                        itemData={filteredList}
                        itemSize={35}
                        width={width}
                        style={{flexGrow: 1}}
                    >
                        {Entry}
                    </List>
                )}
            </AutoSizer>
        </Grid>
    );
}

const Entry = ({data, index, style}:any) => {
    const entry = data[index];

    const [showDetail, setShowDetail] = useState(false);

    const toggleDetail: MouseEventHandler = e => {
        const target = e.target as HTMLElement;

        if (target?.tagName === 'A' || target?.tagName === 'BUTTON')
            return;

        e.preventDefault();

        setShowDetail(detail => !detail);
    }

    return (
        <Row style={style} className={index % 2 === 0 ? 'grey' : ''} onClick={toggleDetail}>
            <div>{new Date(entry.createdAt).toLocaleDateString()}</div>
            <div><a href={`/consumers/points/${entry.account.consumer?.id}`}>{entry.account.firstName} {entry.account.lastName}</a></div>
            <div>{entry.entryType}</div>
            <div style={{textAlign:'right'}}>{entry.quantity.toLocaleString()}</div>
            <div>{entry.accountByCreatedBy.firstName} {entry.accountByCreatedBy.lastName}</div>
            <div title={entry.note}>{entry.note}</div>
            {showDetail && (
                createPortal(
                    <Detail entry={entry} onClose={() => setShowDetail(false)}/>,
                    document.body
                )
            )}
        </Row>
    );
}

const Row = styled.div`
    display: grid;
    grid-template-columns: 6rem 1fr 5rem 7rem 1fr 1fr;
    grid-column-gap: .5rem;
    white-space: nowrap;
    padding: 0 8px;
    align-items: center;
    cursor: pointer;
    
    & > div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    & > div:first-child {
        overflow: visible;
    }
    
    &.grey {
        background: #eee;
    }
    
    &:hover {
        background: #ff0;
    }
`;

const Cell = styled.div`
    position: relative;
    display: grid;
`;

const Heading = styled(Row)`
    ${Cell} {
        text-align: center;
    }
        
    &:hover {
        background: #fff;
    }

    padding: 0 25px 0 8px;
    margin-bottom: .5rem;
`;

const Grid = styled.div`
    flex-grow: 1;
    overflow: hidden;
    background: #fff;
`;

const Input = styled.input`
    width: 100%;
`;

const Select = styled.select`
    width: 100%;
`;

interface FilterProps {
    value: string,
    setter: (value: string) => void,
    placeholder: string,
}

const Filter:React.FC<FilterProps> = ({value, setter, placeholder}) => {
    const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => setter(e.target.value);
    return (
        <Input value={value} onChange={changeHandler} placeholder={placeholder}/>
    );
}

interface SelectFilterProps {
    value: string,
    setter: (value: string) => void,
    options: string[]
}

const SelectFilter:React.FC<SelectFilterProps> = ({value, setter, options}) => {
    const changeHandler: ChangeEventHandler<HTMLSelectElement> = (e) => setter(e.target.value);

    return (
        <Select value={value} onChange={changeHandler}>
            <option value="">All</option>
            {options.map(option => <option key={option} value={option}>{option}</option>)}
        </Select>
    );
}
