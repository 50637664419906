import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

import AuthenticatedPage from "../../components/AuthenticatedPage";
import AutoCompleteLocation, {LocationOption} from "../../components/form/AutoCompleteLocation";
import {useLazyQuery, useQuery} from "@apollo/client";
import Spinner from "../../components/Spinner";
import {useLocation} from "react-router-dom";
import loginAs from "../../lib/login-as";
import {AutocompleteLocationDocument, ProvidersByTransactionLocationDocument} from "../../generated/graphql/graphql";

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto;
  padding: 1rem 0;

  label {
    width: 300px;
    margin: 0;
  }
  
  a {
    margin-left: 10em;
    font-size: 12px;
  }
`;

const Results = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h2 {
        text-align: center;
    }
    
    td {
        white-space: nowrap;
    }
`;

const SpinnerWrapper = styled.div`
    position: relative;
    height: 100px;
`;

const Providers = styled.div`
    td:last-child {
        text-align: right;
    }
`;

const preventDefaultAndLogin = (data:any) => (e:any) => {
    e.preventDefault();
    loginAs(data).then();
}

const Location = () => {
    const [findLocations, {data}] = useLazyQuery(AutocompleteLocationDocument);
    const {state:locationState} = useLocation();

    const [location, setLocation] =  useState<LocationOption|null>();

    const navigate = useNavigate();

    useEffect(() => {
        if (!location?.label)
            return;

        let city, state, zip;

        if (location.label.match(/^\d+$/)) {
            zip = location.id;

            if (zip === locationState?.zip)
                return;
        }
        else {
            [, city, state] = location.label.match(/^(.*), (\w\w)$/) || [];

            if (city === locationState?.city && state === locationState?.state)
                return;
        }

        navigate('', {replace: true, state: {city, state, zip}});
    }, [location, navigate, locationState]);

    useEffect(() => {
        if (!locationState)
            return;

        const {city, state} = locationState;

        if (!(city && state))
            return;

        findLocations({
            variables: {
                search: `${city} ${state}`,
                zipCode: '66952'
            }
        });

    }, [locationState, findLocations]);

    useEffect(() => {
        if (!data)
            return;

        const location = (data?.autocompleteLocation || [])
            .filter(({city, state}:any) => city === locationState.city && state === locationState.state)
            .find(() => true);

        if (!location)
            return;

        const {zip, city, state} = location;

        setLocation({
            id: zip,
            label: `${city}, ${state}`
        })
    }, [data, locationState]);

    const {data: providersResults, loading: providersLoading} = useQuery(ProvidersByTransactionLocationDocument, {
        variables: {
            city: locationState?.city || '',
            state: locationState?.state || '',
            zip: locationState?.zip || ''
        }
    });

    const providers = ((providersResults?.providersByTransactionLocation?.nodes) || [])
        .slice();

    providers.sort((a, b) => {
        if (a.count !== b.count)
            return b.count - a.count;

        if (!(a.provider && b.provider))
            return 0;

        return a.provider.syntheticStatus?.localeCompare(b.provider.syntheticStatus || '') || 0;
    });

    //providers.sort((a, b) => getProviderCompareString(a).localeCompare(getProviderCompareString(b)));

    const onSelect = (location:LocationOption|null) => {
        if (!location)
            return;

        setLocation(location);
    }

    return (
        <AuthenticatedPage title="Location" fullWidth={true} padding="0 0 1rem">
            <Bar><AutoCompleteLocation onSelect={onSelect} defaultValue={locationState ? {id: '', label: locationState.zip || (locationState.city && `${locationState.city}, ${locationState.state}`) || ''} : undefined}/></Bar>
            {location && <Results>
                <Providers>
                    <h2>Providers</h2>
                    {providersLoading ? <SpinnerWrapper><Spinner/></SpinnerWrapper> : providers.length === 0 ? 'No Providers' : (
                        <table>
                            <thead>
                                <tr>{['Name', 'Location', 'Status', 'Txns in Area'].map(label => <th key={label}>{label}</th>)}</tr>
                            </thead>
                            <tbody>
                            {providers.map(({provider, count}) => {
                                if (!provider)
                                    return null;

                                const {info} = provider;

                                const {city, state} = (info && info.location) ||
                                        (info && info.brokerage) ||
                                        {city:'', state:''};

                                return (
                                    <tr key={provider.id}>
                                        <td><a href={`/providers/${provider.id}`} onClick={preventDefaultAndLogin(provider.account)}>{provider.name}</a></td>
                                        <td>{city}, {state}</td>
                                        <td>{provider.syntheticStatus}</td>
                                        <td>{count}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    )}
                </Providers>
            </Results>}
        </AuthenticatedPage>
    )
};

export default Location;