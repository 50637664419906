import React, {useCallback} from 'react';

import AuthenticatedPage from "../../components/AuthenticatedPage";
import {COLUMNS, ConsumersList} from "../Consumers/Consumers";
import {useAllConsumerDeals} from "../../lib/useAllConsumers";
import {useMutation} from "@apollo/client";
import {UpdateInquiryDocument} from "../../generated/graphql/graphql";


const memberLeadsTrack = (c:any) => {
    return c.deal?.track === 'Member Leads';
};

const PIPELINE_COLUMNS = COLUMNS
    .filter(column => !column.label || !['Progress', 'Email', 'Source'].includes(column.label));

const ADMINS = ['Justin', 'Tony', 'Kristina', 'Kumar'];
function AdminSelect({data}: any) {
    const {inquiry, admin} = data;

    const [updateInquiry] = useMutation(UpdateInquiryDocument);

    const flags = Object.assign({}, inquiry?.flags);

    const updateAdmin = useCallback((e:any) => {
        const admin = e.target.value;

        updateInquiry({
            variables: {
                input: {
                    nodeId: inquiry?.nodeId || '',
                    patch: {
                        flags: {...flags, admin}
                    }
                }
            }
        }).then();
    }, [inquiry, updateInquiry, flags]);

    return (
        <select onChange={updateAdmin} value={admin ?? ''}>
            <option value={''}></option>
            {ADMINS.map(admin => <option key={admin} value={admin}>{admin}</option>)}
        </select>
    )
}

const ADMIN_STATUSES = ['New', 'Prepped', 'SMS Sent', 'Responded', 'Finalizing', 'To Discuss', 'Campaign?', 'Active Campaign', 'Holding', 'Done'];
function AdminStatusSelect({data}: any) {
    const {inquiry, deal: {id : dealId}, adminStatus} = data;

    const [updateInquiry] = useMutation(UpdateInquiryDocument);

    const flags = Object.assign({}, inquiry?.flags);
    const dealFlags = Object.assign({}, flags[dealId]);

    const updateAdminStatus = useCallback((e:any) => {
        dealFlags.adminStatus = e.target.value;

        updateInquiry({
            variables: {
                input: {
                    nodeId: inquiry?.nodeId || '',
                    patch: {
                        flags: {...flags, [dealId]: dealFlags}
                    }
                }
            }
        }).then();
    }, [inquiry, dealId, updateInquiry, flags, dealFlags]);

    return (
        <select onChange={updateAdminStatus} value={adminStatus ?? ''}>
            <option value={''}></option>
            {ADMIN_STATUSES.map(adminStatus => <option key={adminStatus} value={adminStatus}>{adminStatus}</option>)}
        </select>
    )
}

PIPELINE_COLUMNS.splice(3, 0,
    {label: 'Admin', width: '75px', field: 'admin', type: 'select', sort: false, render: data => {
        return (
            <AdminSelect data={data}/>
        )
    }},
);

PIPELINE_COLUMNS.splice(4, 0,
    {label: 'Status', width: '125px', field: 'adminStatus', type: 'select', sort: false, render: data => {
            return (
                <AdminStatusSelect data={data}/>
            )
        }},
);

PIPELINE_COLUMNS.push({label: 'Connect By', width: '100px', field: 'connectBy', align: 'right', sort: 'connectByDate'});

const DEFAULT_SORT = {
    column: PIPELINE_COLUMNS.length - 3,
    descending: true
};


const Pipeline = () => {
    const consumers = useAllConsumerDeals();

    return (
        <AuthenticatedPage title="Pipeline" fullWidth={true} padding="0 0 1rem">
            <ConsumersList consumers={consumers} filter={memberLeadsTrack} columns={PIPELINE_COLUMNS} defaultSort={DEFAULT_SORT}/>
        </AuthenticatedPage>
    );
};

export default Pipeline;
