import React, {useMemo} from 'react';
import formatNumber from "format-number";

import {lookupPartner} from "./Providers";
import {findContact} from "../../lib/misc";
import {useQuery} from "@apollo/client";
import {Provider, ProvidersDocument} from "../../generated/graphql/graphql";

const CSV:React.FC = () => {
    const {data, loading} = useQuery(ProvidersDocument);

    const providers = data?.allProviders?.nodes;

    const csv = useMemo(() => {
        if (!providers)
            return '';

        const rows = [
            ['Name', 'Email', 'Phone', 'Brokerage', 'City', 'State', 'Status', 'Profile', 'Tier', 'Created', 'Referrer', 'Entry Page', 'Campaign', 'Partner', 'Milestone', 'APA Expiration']
        ];

        providers.forEach(provider => {
            if (!provider)
                return;

            const mappedFields = mapFields(provider as Provider);

            if (!mappedFields)
                return;

            const {name, email, phone, info, date, syntheticStatus, minPrice, maxPrice, campaign, engagebay, profileComplete, docusignExpiration, created} = mappedFields;
            const brokerage = info && info.brokerage;

            const {city, state} = (info && info.location) ||
            // (cities && 0 < cities.length && cities[0]) ||
            brokerage ||
            {city:'', state:''};
            // const {city, state} = cities && 0 < cities.length ? cities[0] : {city: '', state: ''};

            const milestones = (engagebay && engagebay.deals && Object.values(engagebay.deals).map((deal:any) => `${deal.track}/${deal.milestone}`)) || [];

            rows.push([
                name,
                email,
                phone,
                brokerage ? brokerage.name : '',
                city,
                state,
                syntheticStatus,
                `${profileComplete}`,
                !maxPrice || maxPrice <= 0 ? '' : `${formatLimit(minPrice || 0)} - ${formatLimit(maxPrice)}`,
                date.toLocaleDateString(),
                (campaign && campaign.referrer) || 'direct',
                (campaign && campaign.page) || '',
                (campaign && campaign.c) || '',
                lookupPartner(campaign, new Date(created)) || '',
                milestones.join(', '),
                docusignExpiration,
            ]);
        });

        const csv = encodeURIComponent(rows.map(fields => fields.map(field => field ? `"${field.replaceAll('"', '""')}"` : '').join(',')).join('\n'));

        return `data:text/plain;charset=utf-8,${csv}`;
    }, [providers]);


    if (!(csv && !loading))
        return <span>Loading...</span>;

    return <a href={csv} download='providers.csv'>Download CSV</a>;
};

export default CSV;

const currencyK = formatNumber({prefix:'$', suffix:'K'});
const currencyM = formatNumber({prefix:'$', suffix:'M', round: 2})
const currencyB = formatNumber({prefix:'$', suffix:'B', round: 2})

function formatLimit(v:number) {
    if (v <= 0)
        return '0';
    else if (v < 1000)
        return currencyK(v);
    else if (v < 1000000)
        return currencyM(v / 1000);
    else
        return currencyB(v / 1000000);
}

const mapFields = (p: Provider) => {
    if (!p?.account)
        return null;

    const {account:{nodeId: accountNodeId, firstName, lastName, campaign, contacts}, info, syntheticStatus} = p;

    const email = findContact(contacts, 'email', 'address');

    // const phone = findContact(contacts, 'phone', 'number');

    const date = new Date(p.createdAt);

    const {city, state} = (info?.location) || {city:'', state:''};
    const partner = lookupPartner(campaign, new Date(p.createdAt));
    const brokerage = info?.brokerage?.name;

    const created = new Date(p.createdAt).toLocaleDateString();

    return Object.assign({
        date,
        syntheticStatus,
        accountNodeId,
        firstName,
        lastName,
        campaign,
        partner,
        brokerage,
        created,
        city,
        state,
        cityState: `${city}, ${state}`,
        email,
        docusignExpiration: p.docusignExpiration,
    }, p);
};