import React from 'react';
import {useSubscription} from "@apollo/client";
import useViewer from "./lib/use-viewer";
import {
    UpdateAccountListenerDocument,
    UpdateConsumerListenerDocument,
    UpdateContactListenerDocument,
    UpdateInquiryAddressListenerDocument,
    UpdateInquiryListenerDocument,
    UpdateInvitationListenerDocument, UpdateLeadListenerDocument, UpdateProviderListenerDocument
} from "./generated/graphql/graphql";

function UpdateListener() {
    useSubscription(UpdateAccountListenerDocument);
    useSubscription(UpdateConsumerListenerDocument);
    useSubscription(UpdateContactListenerDocument);
    useSubscription(UpdateInquiryListenerDocument);
    useSubscription(UpdateInquiryAddressListenerDocument);
    useSubscription(UpdateInvitationListenerDocument);
    useSubscription(UpdateLeadListenerDocument);
    useSubscription(UpdateProviderListenerDocument);

    return null;
}

export default function UpdateListenerWrapper() {
    const {viewer} = useViewer();

    if (!viewer)
        return null;

    return <UpdateListener/>;
}