import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useLocation } from "react-router-dom";
import {useQuery, useMutation, useSubscription} from "@apollo/client";
import {Md5} from 'ts-md5';
import styled from "styled-components";
import useViewer from "./lib/use-viewer";
import {
    CreateUserActivityDocument,
    UserActivitiesQueryDocument,
    UserActivityListenerDocument
} from "./generated/graphql/graphql";

interface PageUser {
    nodeId: string;
    firstName: string;
    instanceId: string;
    data: any;
    createdAt: number;
}

const UserLegend = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 65535;
  
  display: flex;
  column-gap: .25em;
  
  span {
    border-radius: .25em;
    background-color: #ff0;
    padding: 0 .25em;
    font-size: 75%;
  }
`;

let instanceId = window.sessionStorage.getItem('userActivityInstanceId');
if (!instanceId) {
    instanceId = ((Date.now() % (24*60*60*1000)) + Math.floor(Math.random() * 360)).toString(36);
    window.sessionStorage.setItem('userActivityInstanceId', instanceId);
}

const UserActivity = () => {
    const {pathname} = useLocation();

    // slice a few chars off the end to fit in notify channel name
    const path = useMemo(() => Md5.hashStr(pathname).substring(0, 30), [pathname]);

    const [users, setUsers] = useState<PageUser[]>([]);

    const [focusId, setFocusId] = useState('');
    const [lastFocusId, setLastFocusId] = useState('');

    const {data, refetch} = useQuery(UserActivitiesQueryDocument, {
        variables: {
            path
        }
    });

    const [createUserActivity] = useMutation(CreateUserActivityDocument);

    useEffect(() => {
        const data: any = {};

        if (focusId)
            data.focusId = focusId;

        const sendActivity = () => {
            createUserActivity({
                variables: {
                    input: {
                        userActivity: {
                            instanceId: instanceId || '',
                            path,
                            data
                        }
                    }
                }
            }).then();

            setLastFocusId(focusId || '');
        };

        if (focusId) {
            if (focusId !== lastFocusId)
                sendActivity();
        }
        else if (!lastFocusId) {
            sendActivity();
        }
        else {
            const timeout = window.setTimeout(sendActivity, 500);

            return () => window.clearTimeout(timeout);
        }
    }, [createUserActivity, path, focusId, lastFocusId]);

    useEffect(() => {
        const focusListener = (e: any) => {
            const id = e.target.id;

            if (id) {
                window.setTimeout(() => {
                    if (document.activeElement === e.target)
                        setFocusId(id);
                }, 250);
            }
        }

        const blurListener = (e: any) => {
            const id = e.target.id;

            if (id)
                setFocusId(_id => _id === id ? '' : _id);
            else if (e.target.tagName === 'WINDOW')
                setFocusId('');
        }

        document.addEventListener('focusin', focusListener);
        document.addEventListener('focusout', blurListener);
        window.addEventListener('blur', blurListener);

        return () => {
            document.removeEventListener('focusin', focusListener);
            document.removeEventListener('focusout', blurListener);
            window.removeEventListener('blur', blurListener);
        }
    }, [setFocusId])

    useEffect(() => {
        setUsers([]);
        setFocusId('');
        setLastFocusId('');
    }, [path]);

    const updateUsers = useCallback((_update: any[], clear?: boolean) => {
        setUsers(_users => {
            const update = _update.slice();//.filter((user, i) => _update.slice(i+1).every(u => u.instanceId !== user.instanceId));

            const users = clear ? [] : _users.filter(u => update.every(user => u.instanceId !== user.instanceId));

            update.forEach(user => {
                const {nodeId, instanceId, account, data, createdAt} = user;

                if (account?.firstName) {
                    users.push({
                        nodeId,
                        instanceId,
                        firstName: account.firstName,
                        data,
                        createdAt: new Date(createdAt).getTime()
                    });
                }
            });

            users.sort((a, b) => parseInt(b.data.instanceId, 36) - parseInt(a.data.instanceId, 36));

            return users;
        });
    }, [setUsers])

    useEffect(() => {
        const users = data?.userActivities?.nodes;

        if (users)
            updateUsers(users, true);
    }, [updateUsers, data?.userActivities?.nodes]);

    const insertSubscription = useSubscription(UserActivityListenerDocument, {
        variables: {
            topic: `insert:UserActivity:${path}`
        }
    });

    useEffect(() => {
        const node = insertSubscription.data?.listen.relatedNode;

        if (!node)
            return;

        updateUsers([node]);

    }, [updateUsers, insertSubscription.data]);

    const deleteSubscription = useSubscription(UserActivityListenerDocument, {
        variables: {
            topic: `delete:UserActivity:${path}`
        }
    });

    useEffect(() => {
        setTimeout(refetch, 250);
    }, [deleteSubscription.data, refetch]);

    const activeUsers = useMemo(() => {
        const activeUsers = users.filter(user => user.instanceId !== instanceId);

        activeUsers.sort((a, b) => parseInt(b.data.instanceId, 36) - parseInt(a.data.instanceId, 36));

        return activeUsers;
    }, [users]);

    const css = useMemo(() => {
        return activeUsers.filter(user => !!user.data.focusId).map(user => `
                #${user.data.focusId} {
                    background-color: hsl(${parseInt(user.instanceId, 36) % 360}, 100%, 85%);
                }
            `).join('\n');
    }, [activeUsers])

    return (
        <UserLegend>
            {activeUsers.map(user => (
                <span
                    key={user.instanceId}
                    style={{backgroundColor: `hsl(${parseInt(user.instanceId, 36) % 360}, 100%, 75%)`}}
                >{user.firstName}</span>
            ))}
            <style dangerouslySetInnerHTML={{__html: css}}/>
        </UserLegend>
    );
}

const UserActivityWrapper = () => {
    const {viewer} = useViewer();

    if (!viewer)
        return null;

    return <UserActivity/>;
}

export default UserActivityWrapper;
