import React from 'react';
import styled from 'styled-components';

import {propFn} from "../../lib/theme";

import Label from './Label';
import Required from './Required';

const property = propFn('input');

export const StyledInput = styled.input`
    box-sizing: border-box;
    font-weight: normal;
    display: block;
    margin-top: 4px;
    background: ${property('background')};
    border: ${property('border')};
    padding: 0 10px;
    outline: none;
    width: 100%;
    height: ${property('height')};
    
    &:focus {
      background: #def;
    }
    
    &.invalid {
        background: #f99;
    }
`;

export interface ComponentProps {
    label?: string,
    [name:string]: any
}

function formatValue(type:string, value:string) {
    switch (type.toLowerCase()) {
        case 'tel':
            return formatPhoneNumber(value);
        case 'numeric':
            return value.replace(/\D+/g, '');
        default:
            return value;
    }
}

function getPattern(type:string, props:any) {
    if (props.pattern)
        return props.pattern;

    switch (type.toLowerCase()) {
        case 'tel':
            return '^\\(\\d{3}\\) \\d{3}-\\d{4}$';
        case 'numeric':
            const {minLength='', maxLength=''} = props;

            if (minLength || maxLength)
                return `^\\d{${minLength},${maxLength}}$`;
            else
                return '^\\d*$';
        default:
            return undefined;
    }
}

const Input = ({label, value, onChange, type='text', ...props}: ComponentProps) => {
    const pattern = getPattern(type, props);

    const changeHandler = (e:any) => {
        const value = formatValue(type, e.target.value);

        e.target.value = value;

        onChange({
            target: {
                name: e.target.name,
                value: value
            }
        });
    };

    const actualType = /^numeric$/.test(type) ? 'text' : type;

    return (
        <Label>
            {label && (
                <span>{label}{props.required && <Required/>}</span>
            )}
            <StyledInput {...props} value={value} onChange={changeHandler} pattern={pattern} type={actualType}/>
        </Label>
    )
};

export default Input;



function formatPhoneNumber(input:string) {
    const value = input
        .replace(/\D+/g, '')
        .replace(/^1/, '');

    const areaCode = value.substring(0, 3);
    const prefix = value.length > 3 ? value.substring(3, 6) : '';
    const number = value.length > 6 ? value.substring(6, 10) : '';

    if (number)
        return `(${areaCode}) ${prefix}-${number}`;
    else if (prefix)
        return `(${areaCode}) ${prefix}`;
    else if (areaCode)
        return `(${areaCode}`;
    else
        return '';
}
