import { Button } from "../form"
import Overlay from "../Overlay";
import {useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import styled from "styled-components";
import {CreateNoteDocument, NotesDocument} from "../../generated/graphql/graphql";

interface Props {
    topic: string;
    topicId: any;
    title?: string;
}

const Notes = ({topic, topicId, title='Notes'}: Props) => {
    const { data, refetch } = useQuery(NotesDocument, {
        variables: {
            topic,
            topicId: String(topicId)
        }
    });

    const [createNote] = useMutation(CreateNoteDocument);

    const [show, setShow] = useState(false);

    const formSubmit = (event: any) => {
        event.preventDefault();
        const note = event.target.querySelector('textarea').value;
        event.target[0].value = '';
        createNote({
            variables: {
                input: {
                    note: {
                        note,
                        topic,
                        topicId: String(topicId)
                    }
                }
            }
        }).then(() => refetch());
    }

    const notes = data?.notes?.nodes.slice() ?? [];

    notes.sort((a:any, b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    return (
        <>
            <button onClick={() => setShow(true)}>{title} {data?.notes && `(${data.notes.nodes.length})`}</button>
            {show && (
                <Overlay onClick={() => setShow(false)}>
                    <Popup>
                        <h2>{title}</h2>
                        <ScrollNotes>
                            {notes.map((note:any) => (<Note key={note.nodeId} note={note}/>))}
                        </ScrollNotes>
                        <form onSubmit={formSubmit}>
                            <textarea name="newNote" placeholder="Add a note"/>
                            <Button variation="transparent" onClick={() => setShow(false)}>Close</Button>
                            <Button>Add Note</Button>
                        </form>
                    </Popup>
                </Overlay>
            )}
        </>
    )
}

const Popup = styled.div`
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,.25);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 16384;
    min-width: 300px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    text-align: left;
    
    h2 {
        margin-top: 0;
    }
    
    form {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        gap: 1rem;

        textarea {
            flex: 1 1 100%;
            height: 5em;
            border: 1px solid #ccc;
        }
        
        ${Button} {
            padding: .25em;
        }
    }
`;

const ScrollNotes = styled.div`
    max-height: 50vh;
    overflow: auto;
    
    > div {
        border-bottom: 1px solid #ccc;
    }
    
    > div:last-child {
        border-bottom: none;
    }
`;

const Note = ({note}: {note: any}) => {
    const date = (new Date(note.createdAt)).toLocaleString();
    return (
        <NoteWrapper>
            <div>{note.note}</div>
            <div>{note.account.firstName} {note.account.lastName}</div>
            <div>{date}</div>
        </NoteWrapper>
    );
}

const NoteWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 0 .5em;
    padding: .5em 0;
    
    > div:nth-child(2), > div:nth-child(3) {
        font-size: 12px;
        color: #666;
    }
    
    > div:first-child {
        flex: 1 1 100%;
        white-space: break-spaces;
    }
`

export default Notes;