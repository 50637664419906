import React, {ChangeEventHandler, useCallback, useEffect, useMemo, useState} from 'react';
import localforage from "localforage";
import AuthenticatedPage from "../../components/AuthenticatedPage";

import Spinner from "../../components/Spinner";
import SelectedPresented from "../../components/SelectedPresented";
import styled from "styled-components";
import useViewer from "../../lib/use-viewer";

import AccountMenu from "../../components/AccountMenu";
import {Link} from "react-router-dom";
import {getPartner} from "../Consumers/Consumers";
import CSV from "./CSV";
import loginAs from "../../lib/login-as";
import {useQuery} from "@apollo/client";

import {FixedSizeList as List} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {Provider, ProvidersListDocument, ProvidersListQuery} from "../../generated/graphql/graphql";

localforage.config({ name: 'rbn' });

const Providers:React.FC = () => {
    const {viewer} = useViewer();

    const [createCSV, setCreateCSV] = useState(false);

    return (
        <AuthenticatedPage title="Providers" fullWidth={true} padding="0">
            <Header>
                <a href={`${SITE_BASE}/apply-now?onboarded-by=${viewer && encodeURIComponent(viewer.id)}`} target="onboard">Onboard New Provider</a>
                {createCSV ? <CSV/> : <button onClick={() => setCreateCSV(true)}>Generate CSV</button>}
                <a href={`/providers/map`}>Map</a>
            </Header>
            <ProvidersTable/>
        </AuthenticatedPage>
    );
}

export default Providers;

const Header = styled.div`
    padding: 1rem;
    text-align: right;
    
    & > * {
        margin-left: 4rem;
    }
`;

const Input = styled.input`
    width: 100%;
`;

const Select = styled.select`
    width: 100%;
`;

interface FilterProps {
    value: string,
    setter: (value: string) => void,
    placeholder: string,
}
const Filter:React.FC<FilterProps> = ({value, setter, placeholder}) => {
    const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => setter(e.target.value);
    return (
        <Input value={value} onChange={changeHandler} placeholder={placeholder}/>
    );
}

interface SelectFilterProps {
    value: string,
    setter: (value: string) => void,
    options: string[]
}

const SelectFilter:React.FC<SelectFilterProps> = ({value, setter, options}) => {
    const changeHandler: ChangeEventHandler<HTMLSelectElement> = (e) => setter(e.target.value);

    return (
        <Select value={value} onChange={changeHandler}>
            <option value="">All</option>
            {options.map(option => <option key={option} value={option}>{option}</option>)}
        </Select>
    );
}

const Rdiv = styled.div`
    text-align: right;
`;

const SITE_BASE = 'https://joinrbn.com';

const Row = styled.div`
    display: grid;
    grid-template-columns: 12px 1.75fr 50px 50px 2fr 1fr 1fr 1fr 1fr .75fr .75fr;
    grid-column-gap: .5rem;
    white-space: nowrap;
    padding: 0 8px;
    align-items: center;
    
    & > div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    & > div:first-child {
        overflow: visible;
    }
    
    &.grey {
        background: #eee;
    }
    
    &:hover {
        background: #ff0;
    }
`;

const Cell = styled.div`
    position: relative;
    display: grid;
`;

const ProvidersHeading = styled(Row)`
    ${Cell} {
        text-align: center;
    }
        
    &:hover {
        background: #fff;
    }

    padding: 0 25px 0 8px;
    margin-bottom: .5rem;
`;

const Grid = styled.div`
    flex-grow: 1;
    overflow: hidden;
    background: #fff;
`;


const preventDefaultAndLogin = (data:any) => (e:any) => {
    e.preventDefault();
    loginAs(data).then();
}

const ProvidersTable:React.FC = () => {
    const {data} = useQuery(ProvidersListDocument);
    const [nameFilter, setNameFilter] = useState('');
    const [emailFilter, setEmailFilter] = useState('');
    const [partnerList, setPartnerList] = useState<string[]>([]);
    const [partnerFilter, setPartnerFilter] = useState('');
    const [brokerageFilter, setBrokerageFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState('');
    const [statusList, setStatusList] = useState<string[]>([]);
    const [statusFilter, setStatusFilter] = useState('');

    const [providersData, setProvidersData] = useState<ProvidersListQuery | undefined>();

    useEffect(() => {
        if (data) {
            setProvidersData(data);

            localforage.setItem('providersData', data)
                .then(() => {
                    // console.log(`${Date.now()}: updated providersData`)
                })
                .catch(console.error);
        }
        else {
            localforage.getItem('providersData').then(providersData => {
                setProvidersData(_providersData => {
                    if (_providersData)
                        return _providersData;
                    else if (providersData) {
                        // console.log(`${Date.now()}: using cached providersData`);
                        return providersData as ProvidersListQuery;
                    }
                    else
                        return undefined;
                });
            });
        }

    }, [data, setProvidersData]);

    const providers = providersData?.allProviders?.nodes;

    const list = useMemo(() => {
        if (!providers)
            return null;

        const partners:{[partner: string]: boolean} = {};
        const status:{[status: string]: boolean} = {};

        const list = providers.map(provider => {
            const partner = lookupPartner(provider.account?.campaign, new Date(provider.createdAt));
            const syntheticStatus = provider.syntheticStatus;

            if (partner)
                partners[partner] = true;

            if (syntheticStatus)
                status[syntheticStatus] = true;

            return {
                provider,
                name: provider.name,
                engagebayId: provider.engagebayId,
                email: provider.email,
                campaign: formatCampaign(provider.account?.campaign),
                partner,
                syntheticStatus,
                createdAt: provider.createdAt ? new Date(provider.createdAt).toLocaleDateString() : '',
                profileComplete: provider.profileComplete || 0,
                brokerage: provider.brokerageName,
                location: provider.location,
                progress: provider.profileComplete,
                lc: {
                    name: provider.name.toLowerCase(),
                    email: provider.email.toLowerCase(),
                    brokerage: provider.brokerageName?.toLowerCase() || '',
                    cityState: provider.location ? `${provider.location.city}, ${provider.location.state}`.toLowerCase() : '',
                }
            };
        });

        setPartnerList(Object.keys(partners).sort());
        setStatusList(Object.keys(status).sort());

        return list;
    }, [providers, setPartnerList, setStatusList]);

    const filteredList = useMemo(() => {
        if (!list)
            return null;

        const nameMatch = nameFilter?.toLowerCase();
        const emailMatch = emailFilter?.toLowerCase();
        const brokerageMatch = brokerageFilter?.toLowerCase();
        const locationMatch = locationFilter?.toLowerCase();

        return list.filter(({partner, syntheticStatus, lc: {name, email, brokerage, cityState}}) => {
            if (nameMatch && !name.match(nameMatch))
                return false;

            if (emailMatch && !email.match(emailMatch))
                return false;

            if (partnerFilter && partner !== partnerFilter)
                return false;

            if (brokerageMatch && !brokerage.match(brokerageMatch))
                return false;

            if (locationMatch && !cityState.match(locationMatch))
                return false;

            return !(statusFilter && syntheticStatus !== statusFilter);
        });
    }, [list, nameFilter, emailFilter, partnerFilter, brokerageFilter, locationFilter, statusFilter]);

    const generateRow = useCallback(({data, index, style}:any) => {
        const {name, engagebayId, campaign, partner, email, brokerage, location, syntheticStatus, progress, createdAt, provider} = data[index];

        const account = provider.account;
        const signed = provider.signed;

        return (
            <Row style={style} className={index % 2 === 0 ? 'grey' : ''}>
                <AccountMenu data={{
                    provider: provider as Provider,
                    nodeId: provider.nodeId,
                    firstName: account?.firstName || '',
                    lastName: account?.lastName || '',
                    accountId: account?.id || '',
                    signed,
                }}/>
                <a href={`/providers/${provider.id}`} onClick={preventDefaultAndLogin(account)} title={name}>{name}</a>
                <div style={{textAlign: 'center'}}><SelectedPresented {...provider}/></div>
                <div>
                    <a href={`https://joinrbn.engagebay.com/home#list/0/subscriber/${engagebayId}`} target="engagebay">EB</a>
                    &nbsp;&nbsp;
                    <span title={campaign}>&copy;</span>
                </div>
                <div title={email}>{email}</div>
                <div title={partner}>{partner}</div>
                <div title={brokerage}>{brokerage}</div>
                <div title={location?.city && `${location.city}, ${location.state}`}>
                    {location?.city && <Link to="/location" state={{city: location.city, state: location.state}}>{`${location.city}, ${location.state}`}</Link>}
                </div>
                <div>{syntheticStatus}</div>
                <Rdiv>{progress}%</Rdiv>
                <Rdiv>{createdAt}</Rdiv>
            </Row>
        );
    }, []);

    if (!filteredList)
        return <Spinner/>;

    const placeholder = `Search ${filteredList.length} provider${filteredList.length === 1 ? '' : 's'}...`;

    return (
        <Grid>
            <ProvidersHeading>
                <Cell>&nbsp;</Cell>
                <Cell>
                    <strong>Name</strong>
                    <Filter value={nameFilter} setter={setNameFilter} placeholder={placeholder}/>
                </Cell>
                <Cell>
                    <strong>S:P</strong>
                </Cell>
                <Cell>&nbsp;</Cell>
                <Cell>
                    <strong>Email</strong>
                    <Filter value={emailFilter} setter={setEmailFilter} placeholder={placeholder}/>
                </Cell>
                <Cell>
                    <strong>Source</strong>
                    <SelectFilter value={partnerFilter} setter={setPartnerFilter} options={partnerList}/>
                </Cell>
                <Cell>
                    <strong>Brokerage</strong>
                    <Filter value={brokerageFilter} setter={setBrokerageFilter} placeholder={placeholder}/>
                </Cell>
                <Cell>
                    <strong>Location</strong>
                    <Filter value={locationFilter} setter={setLocationFilter} placeholder={placeholder}/>
                </Cell>
                <Cell>
                    <strong>Status</strong>
                    <SelectFilter value={statusFilter} setter={setStatusFilter} options={statusList}/>
                </Cell>
                <Cell>
                    <Rdiv><strong>Progress</strong></Rdiv>
                </Cell>
                <Cell>
                    <Rdiv><strong>Created</strong></Rdiv>
                </Cell>
            </ProvidersHeading>
            <AutoSizer>
                {({height, width}: {height: number, width: number}) => (
                    <List
                        height={height - 50}
                        itemCount={filteredList.length}
                        itemData={filteredList}
                        itemSize={35}
                        width={width}
                        style={{flexGrow: 1}}
                    >
                        {generateRow}
                    </List>
                )}
            </AutoSizer>
        </Grid>
    );
};

export function lookupPartner(campaign:any, createDate: Date): string {
    if (!campaign)
        return '';

    let partner = getPartner(campaign, createDate);

    if ((!partner || ['Direct', 'Organic'].includes(partner)) && campaign.c) {
        switch (campaign.c) {
            case 'sms':
                partner = 'ASR';
                break;
            case 'lre':
                partner = 'LRE';
                break;
            case 'exp':
                partner = 'EXP';
                break;
            case 'invited':
                partner = 'Invited';
                break;
        }
    }

    if (partner === 'The Points Guy')
        partner = 'TPG';

    return partner;
}

function formatCampaign(campaign:any) {
    if (!campaign)
        return undefined;

    const {referrer, page, ...params} = campaign;

    const keys = Object.keys(params);
    keys.sort();
    keys.unshift('page', 'referrer');

    return keys.map(k => `${k}: ${campaign[k]}`).join('\n');
}
