import React, {ChangeEventHandler, useContext, useState} from 'react';
import {findContact} from "../../lib/misc";
import {useMutation} from "@apollo/client";
import {api, JWT_KEY} from "../../config";
import Overlay from "../../components/Overlay";
import {Button, Buttons} from "../../components/form";
import styled from "styled-components";
import {AgentFinderContext, formatPoints, formatPrice} from "./AgentFinder";
import {Consumer, Inquiry, UpdateInquiryDocument} from "../../generated/graphql/graphql";


interface SmsPopupProps {
    consumer: Consumer;
    inquiry: Inquiry;
    close: () => void;
}

export const SmsPopup: React.FC<SmsPopupProps> = ({consumer, inquiry, close}) => {
    const phone = findContact(consumer?.account?.contacts, 'phone', 'number');

    const [updateInquiry] = useMutation(UpdateInquiryDocument);

    const created = new Date(consumer.createdAt).toDateString().replace(/^\S+\s(\S+)\s[0]?(\d+).*$/, '$1. $2');

    const { city, deal } = useContext(AgentFinderContext);

    const [sms, setSms] = useState(`Hi ${consumer.account?.firstName}, this is Malia with RBN. We're just checking in on your RBN that you started back on ${created}... Are you still in the market to ${inquiry?.details?.buying?.price ? 'buy' : 'sell'} a home in ${city}? We'd like to help you earn AmEx points with your home ${inquiry?.details?.buying?.price ? 'purchase' : 'sale'} - on a ${formatPrice(deal.amount)} home purchase, you can earn up to ${formatPoints(deal.amount)} AmEx points with an RBN participating agent. Schedule your personalized consultation with our Concierge Team at www.joinrbn.link/support`);

    const smsChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
        setSms(e.target.value);
    }

    const smsSent = inquiry.details.sms;

    const sendSms = () => {
        const body = JSON.stringify({
            to: phone,
            from: '6028992787',
            message: sms
        });

        fetch(`${api.endpoint}/send-sms`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
                'Content-Type': 'application/json'
            },
            body
        })
            .then(() => {
                updateInquiry({
                    variables: {
                        input: {
                            nodeId: inquiry.nodeId,
                            patch: {
                                details: {
                                    ...inquiry.details,
                                    sms
                                }
                            }
                        }
                    }
                }).then();
            });
    }

    return (
        <Overlay onClick={close}>
            <SmsPopupContainer>
                <h2>Customer Follow Up SMS</h2>

                {smsSent ? <div style={{maxWidth: "600px"}}>{smsSent}</div> : <textarea value={sms} onChange={smsChange}/>}
                <Buttons>
                    {!smsSent && <Button onClick={sendSms}>Send SMS</Button>}
                    <Button onClick={close} variation={smsSent ? undefined : "white"}>{smsSent ? 'Done' : 'Cancel'}</Button>
                </Buttons>
            </SmsPopupContainer>
        </Overlay>
    )
}

const SmsPopupContainer = styled.div`
    padding: 1rem;
    background: #fff;
    
    textarea {
        width: 600px;
        height: 10rem;
    }
`;