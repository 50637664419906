import React, {useCallback, useEffect, useMemo} from 'react';
import AuthenticatedPage from "../../components/AuthenticatedPage";
import AutoCompleteLocation from "../../components/form/AutoCompleteLocation";
import {useLazyQuery} from "@apollo/client";
import Spinner from "../../components/Spinner";
import styled from "styled-components";
import formatNumber from "format-number";
import {useLocation} from "react-router-dom";
import {AgentsByCityStateDocument} from "../../generated/graphql/graphql";

const formatInt = formatNumber({truncate:0});

const Results = styled.div`
    h1 {
        margin: 0;
    }
`;

const DownloadLink = styled.a`
    display: block;
    margin-top: 2rem;
    font-size: 150%;
    font-weight: bold;
`;

const AgentDb:React.FC = () => {
	const {state:incomingState} = useLocation();
	const {city, state} = incomingState || {city:undefined, state:undefined};
    const [agentsByCityState, {loading, data}] = useLazyQuery(AgentsByCityStateDocument);

    const locationSelected = useCallback((data:any) => {
        if (!data?.label)
            return;

        const [city, state] = data.label.split(', ');

        agentsByCityState({
            variables: {city, state}
        }).then();
    }, [agentsByCityState]);

	useEffect(() => {
		if (city && state) {
			agentsByCityState({
				variables: {city, state}
			}).then();
		}
	}, [city, state, agentsByCityState])

    const agents = data?.agentsByCityState?.nodes;

    const csv = useMemo(() => {
        const rows:Array<Array<any>> = [HEADERS];

        agents?.forEach(agent => {
            const phone = `1${agent.phone?.replace(/\D+/g, '')}`;

            const row = [agent.firstName, agent.lastName, `+${phone}@noemail.com`, 'www.rbnagents.com', agent.companyName, phone, 'REDB', agent.city, agent.state, phone];

            rows.push(row);
        });

        const csv = encodeURIComponent(rows.map(fields => fields.map(field => typeof field === 'string' ? `"${field.replaceAll('"', '""')}"` : field || '').join(',')).join('\n'));

        return `data:text/plain;charset=utf-8,${csv}`;
    }, [agents]);

    return (
        <AuthenticatedPage>
            <AutoCompleteLocation label="Area" onSelect={locationSelected} defaultValue={city && state ? {id: '', label: `${city}, ${state}`} : undefined}/>
            {loading && <Spinner/>}
            {!loading && agents && (
                <Results>
                    <h1>Found {formatInt(agents.length)} matching agents</h1>
                    <h3>sample:</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Brokerage</th>
                            </tr>
                        </thead>
                        <tbody>
                    {agents.slice(0, 15).map((agent, i) => {
                        return (
                            <tr key={i}>
                                <td>{agent.firstName}</td>
                                <td>{agent.lastName}</td>
                                <td>{agent.email}</td>
                                <td>{agent.phone}</td>
                                <td>{agent.companyName}</td>
                            </tr>
                        );
                    })}
                        </tbody>
                    </table>
                    <DownloadLink href={csv} download="as-eb-agent-import.csv">Download CSV</DownloadLink>
                </Results>
            )}
        </AuthenticatedPage>
    )
};

export default AgentDb;

const HEADERS = [
    'First Name',
	'Last Name',
	'Email (Primary)',
	'Video URL',
	'Company',
	'Phone (Mobile)',
	'Data Source',
	'Target City',
	'Target State',
	'Primary Key'
];