import {version} from "./version";
import {JWT_KEY} from "./authentication";

const server = () => {
    let v = version;

    if (v === 'local') {
        if (sessionStorage.getItem('localAPI'))
            return sessionStorage.getItem('localAPI');

        const match = document.location.hostname.match(/^(dev|staging)\.rbnrewards\.com$/);

        if (match)
            v = match[1];
        else
            v = 'production';
    }

    return ({
            'local': sessionStorage.getItem('localAPI') ?? `https://dev.api.rbnrewards.com`,
            'dev': `https://dev.api.rbnrewards.com`,
            'staging': `https://api.rbnrewards.com`,
            'production': `https://api.rbnrewards.com`,
        }[v] || `https://api.rbnrewards.com`
    );
};

export const api = {
    server: server(),
    endpoint: `${server()}/api`,
    call: (path: string, method='GET', body?: any) => {
        const jwt = localStorage.getItem(JWT_KEY);

        const headers: any = {};

        if (jwt)
            headers['Authorization'] = `Bearer ${jwt}`;

        if (body)
            headers['Content-Type'] = 'application/json';

        return fetch(`${api.endpoint}/${path.replace(/^\//, '')}`, {
            method,
            headers,
            body: body ? JSON.stringify(body) : undefined
        });
    },
    get: (path: string) => api.call(path),
    post: (path: string, body: any) => api.call(path, 'POST', body),
    put: (path: string, body: any) => api.call(path, 'PUT', body),
    delete: (path: string) => api.call(path, 'DELETE'),
};

if (version !== 'production') {
    fetch('http://localhost:5000/api/test')
        .then(response => response.json())
        .then(json => {
            if (json.server !== 'RBN')
                throw Error('No local server!');

            sessionStorage.setItem('localAPI', 'http://localhost:5000');

            console.log('Using local API server');
        })
        .catch(() => {
            sessionStorage.removeItem('localAPI');

            console.log('Local API server not found');
        })
        .finally(() => {
            api.server = server();
            api.endpoint = `${server()}/api`;
        })
}
