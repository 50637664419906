import React, {ChangeEventHandler, FocusEventHandler, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";

import {SearchLink} from "./AgentFinder";
import {useMutation, useQuery} from "@apollo/client";
import {
    ProviderTransaction,
    ConsumerDealsResponse,
    DealAddressesResponse,
    UpdateDealAddressPriceDocument, ConsumerByIdDocument,
} from "../../generated/graphql/graphql";

const StyledInput = styled.input`
    border: 1px solid #666;
`;

const AddressesWrapper = styled.div`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 1rem;
    
    label {
        text-align: right;
        grid-column: 1;
    }
`;

type Transactions = ProviderTransaction;

interface Props {
    // inquiry: Inquiry;
    transactions: Transactions[] | null | undefined;
    deal: ConsumerDealsResponse;
}

const Addresses: React.FC<Props> = ({deal, transactions}) => {
    const addresses = deal?.addresses?.nodes;

    if (!addresses?.length)
        return null;

    return (
        <AddressesWrapper>
            {addresses.map(address => <Address address={address} key={address.id} deal={deal} transactions={transactions}/>)}
        </AddressesWrapper>
    )
};

export default Addresses;

const AddressContainer = styled.div`
    grid-column: 2;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    
    input {
        width: 8em;
        text-align: right;
    }
    
    span {
        font-size: 80%;
    }
`;

function cleanPrice(input: any) {
    if (typeof input === 'number')
        return input;

    if (!input)
        return null;

    return parseInt(input.replace(/[^.\d]+/g, ''))
}

interface AddressProps {
    address: DealAddressesResponse;
    transactions: any[] | null | undefined;
    deal: ConsumerDealsResponse;
}

function formatPrice(price?: string|number|null) {
    if (!price)
        return '';

    const n = Number(String(price).replace(/[^.\d]/g, ''));

    return n.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0});
}

const Address: React.FC<AddressProps> = ({address, transactions, deal}) => {
    const {refetch} = useQuery(ConsumerByIdDocument, {variables:{id: deal.consumerId}});


    const [updateDealAddressPrice] = useMutation(UpdateDealAddressPriceDocument);

    const [input, setInput] = useState(formatPrice(address?.price));

    const priceHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInput(formatPrice(e.target.value));
    }

    const {city, state} = useMemo(() => {
        if (address?.address) {
            const match = address.address.match(/,\s*([^,]+),\s*([A-Z]{2})(?:\s+(\d{5}))?/);

            if (match) {
                const [, city, state] = match;

                return {city, state};
            }
        }

        return {city: null, state: null}
    }, [address]);

    const matchingTransactionInfo = useMemo(() => {
        if (!(transactions && city && state))
            return '';

        const c = city.toLowerCase();
        const s = state.toLowerCase();

        const filtered = transactions.filter(({city, state}) => (city||'').toLowerCase() === c && (state||'').toLowerCase() === s);
        filtered.sort((a, b) => new Date(a.closeDate).getTime() - new Date(b.closeDate).getTime());

        if (filtered.length === 0)
            return '';

        const latest = new Date(filtered[0].closeDate);
        const average = filtered.reduce((total, {closePrice}) => total + closePrice, 0) / filtered.length;

        return `${filtered.length} txn${1 < filtered.length ? 's' : ''} - avg $${average.toLocaleString('en-US', {maximumFractionDigits: 0})} - most recent ${latest.getMonth() + 1}/${latest.getFullYear()}`;
    }, [transactions, city, state]);

    useEffect(() => {
        setInput(formatPrice(address?.price));
    }, [address?.price, setInput]);

    const priceBlurHandler: FocusEventHandler<HTMLInputElement> = () => {
        // if (!inquiryAddress)
        //     return;

        const price = cleanPrice(input);

        if (price !== cleanPrice(address.price)) {
            updateDealAddressPrice({
                variables: {
                    input: {
                        id: address.id,
                        price
                    }
                }
            }).then(() => refetch());
        }
    }

    return (
        <AddressContainer>
            <SearchLink term={`${address.address}`}/>
            <div>
                <StyledInput placeholder="price" value={input} onChange={priceHandler}
                             onBlur={priceBlurHandler} id={`agent-finder-address-price-${address?.id}`}/>
            </div>
            <span>{matchingTransactionInfo}</span>
        </AddressContainer>
    );
};
